import styled from 'styled-components';
import { Color } from './Styles';

const height = '8%';

export const HeaderDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  background: ${Color.Gray4};
`;

export const LogoGS3 = styled.img.attrs(() => ({ draggable: 'false' }))`
  margin: 20px;
  width: 55px;
  user-select: none;
  cursor: pointer;
`;

export const StructureName = styled.span`
  margin-left: 20px;
  user-select: none;
  display: flex;
  align-items: center;
`;
