import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import React from 'react';
import { CiImageOff } from 'react-icons/ci';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  FindAllAtoImagesDocument,
  FindAllAtoImagesQuery,
  FindAllAtoImagesQueryVariables
} from '../../../../data/graphql/query/generated/findAllAtoImages';
import { BUCKET_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import { PictureGallery } from '../../Molecules/PictureGallery/PictureGallery';
import ContentActionCard from '../../Organisms/ContentActionCard/ContentActionCard';
import SeeMoreTemplate from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import { ContainerImagesNotFound, HolderPictures } from './AtoPhotosStyles';

const AtoPhotos = () => {
  const { atoId } = useParams<{ atoId: string }>();
  const { structureId } = useParams<{ structureId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValue = searchParams.get('search') || '';
  const navigate = useNavigate();
  const { data: getAllAtoImages, loading } = useQuery<
    FindAllAtoImagesQuery,
    FindAllAtoImagesQueryVariables
  >(FindAllAtoImagesDocument, {
    variables: {
      atoId: atoId ? atoId : ''
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
      toastfyError(errorMessage);
    }
  });

  const listAllAtoImages = getAllAtoImages?.findAllAtoImages
    .filter((image) => image.recordNumber.includes(searchValue))
    .map((image) => {
      return (
        <ContentActionCard key={image.recordNumber} title={image.recordNumber}>
          <HolderPictures>
            <PictureGallery
              files={image.images.map((image) => ({
                url: `${BUCKET_URL}/${image.image}`,
                name: image.image,
                id: image.image
              }))}
              imagesGrid={6}
            />
          </HolderPictures>
        </ContentActionCard>
      );
    });
  const content =
    (listAllAtoImages || []).length > 0 ? (
      listAllAtoImages
    ) : (
      <ContainerImagesNotFound>
        <CiImageOff color="black" size={40} />
        <Text type="h4">{t('ImagesNotSearch')}</Text>
      </ContainerImagesNotFound>
    );
  const navigateBack = () => {
    navigate(`/${structureId}/ato/${atoId}`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ search: event.target.value });
  };

  return (
    <SeeMoreTemplate
      icon={<Icon Icon={IoIosArrowBack} onClick={navigateBack} />}
      title="Fotos"
      header={
        <InputText
          label={t('RdoNumber')}
          type="number"
          value={searchValue}
          onChange={handleSearchChange}
          name="search"
        />
      }
      content={content}
      loading={loading}
    />
  );
};

export default AtoPhotos;
