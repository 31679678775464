import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoFilter } from 'react-icons/io5';
import { useSearchParams } from 'react-router-dom';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Table from '../../Atoms/Table/Table';
import TableBody from '../../Atoms/TableBody/TableBody';
import TableCell from '../../Atoms/TableCell/TableCell';
import TableHead from '../../Atoms/TableHead/TableHead';
import TableHeaderRow from '../../Atoms/TableHeaderRow/TableHeaderRow';
import TableRow from '../../Atoms/TableRow/TableRow';
import ExportTableModal from '../../Organisms/ExportTable/ExportTableComponent';
import InputSearch from '../../Organisms/InputSearch/InputSearch';
import ActionMenu from '../ActionMenu/ActionMenu';
import Paginator from '../Paginator/Paginator';
import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';
import ViewModal from '../ViewModal/ViewModal';
import { TableComponentProps } from './TableComponent.interface';
import {
  DivContainerActions,
  DivContentFooter,
  DivMessageNoData,
  TableHeaderCellTh
} from './TableComponent.styles';

// TODO tirar paginator porque ja tem pageState

const TableComponent = ({
  columns,
  tableData,
  onRow,
  onSearch,
  filterComponent,
  paginator,
  optionsPaginator,
  totalPages,
  exportTable,
  actions,
  showInUrl,
  pageState
}: TableComponentProps<any>) => {
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'ascending' | 'descending';
    sortType?: string;
  } | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showExportModal, setShowExportModal] = useState(false);
  const { t: translate } = useTranslation();
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];

  const filteredTableData = useMemo(() => {
    if (sortConfig?.sortType === 'number') {
      return [...tableData].sort((a, b) =>
        sortConfig.direction === 'ascending'
          ? Number(a[sortConfig.key]) - Number(b[sortConfig.key])
          : Number(b[sortConfig.key]) - Number(a[sortConfig.key])
      );
    }

    if (sortConfig?.sortType === 'text') {
      return [...tableData].sort((a, b) =>
        sortConfig.direction === 'ascending'
          ? a[sortConfig.key].localeCompare(b[sortConfig.key])
          : b[sortConfig.key].localeCompare(a[sortConfig.key])
      );
    }

    return tableData;
  }, [tableData, sortConfig]);

  const handleSort = (key: string, sortType?: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }

    setSortConfig({ key, direction, sortType });

    if (showInUrl) {
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('sortKey', key);
        newParams.set('sortDirection', direction);
        return newParams;
      });
    }
  };

  const handleExport = (selectedColumns: string[]) => {
    if (exportTable) {
      exportTable(selectedColumns);
    }
  };

  return (
    <>
      <DivContainerActions>
        {filterComponent && (
          <Icon
            Icon={IoFilter}
            onClick={() => {
              setSearchParams((prevParams) => {
                const params = new URLSearchParams(prevParams);
                params.set('filter', 'true');
                return params;
              });
            }}
            variant="small"
          />
        )}
        {onSearch && <InputSearch onSearch={onSearch} />}
      </DivContainerActions>
      <Table>
        <TableHead>
          <TableHeaderRow className="header-row">
            {columns.map((column) => (
              <TableHeaderCell
                key={column.key}
                onClick={() => handleSort(column.key, column.sortType)}
                isSorted={sortConfig?.key === column.key}
                isSortedDesc={
                  sortConfig?.key === column.key &&
                  sortConfig?.direction === 'descending'
                }
                sortType={column.sortType}
              >
                {column.label}
              </TableHeaderCell>
            ))}
            {actions && <TableHeaderCellTh></TableHeaderCellTh>}
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {filteredTableData.map((item, index) => (
            <TableRow
              key={index}
              onClick={onRow ? () => onRow(item) : undefined}
            >
              {columns.map((column) => (
                <TableCell key={column.key} color={item.color}>
                  {item[column.key]}
                </TableCell>
              ))}
              {actions && (
                <TableCell>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <ActionMenu
                      availableContextActions={actions}
                      dataDrivenPermissions={item}
                      dataActions={item}
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DivContentFooter>
        {exportTable && tableData.length > 0 && (
          <Button
            text={translate('ExportTable')}
            variant="secondary"
            size="large"
            onClick={() => setShowExportModal(true)}
          />
        )}
        {paginator && tableData.length > 0 && (
          <Paginator
            options={optionsPaginator || optionsPaginatorDefault}
            totalPages={totalPages}
            pageState={pageState}
            showInUrl={showInUrl}
          />
        )}
      </DivContentFooter>
      {tableData.length === 0 && (
        <DivMessageNoData>{translate('noData')}</DivMessageNoData>
      )}
      {searchParams.get('filter') === 'true' && (
        <ViewModal
          height={'auto'}
          width={'auto'}
          title={translate('Filters')}
          onClose={() => {
            const params = new URLSearchParams(searchParams);
            params.delete('filter');
            setSearchParams(params);
          }}
          component={filterComponent}
        />
      )}
      {showExportModal && (
        <ExportTableModal
          columns={columns}
          onExport={handleExport}
          onClose={() => setShowExportModal(false)}
        />
      )}
    </>
  );
};

export default TableComponent;
