import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import Select from 'react-select';
import { InstrumentsTableData, PopupPositionType } from '../../@Types/types';
import { ProjectContext } from '../../Context/ContextAPI';
import PopUp from '../../Modules/Inspection/Register/PopUp';
import { ActionsRow } from '../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../Styles/Styles';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum
} from '../../data/graphql/base-schema';
import { CellWithTooltip } from '../Instruments/Readings/DataBaseStyles';
import { StyleInstrumentSelect } from '../Selects/InstrumentSelect';
import {
  FilterTitle,
  TableComponentStyle,
  TableDivStyle,
  TableHolderFilter,
  TableHolderFilterAndTitle
} from './TablesStyle';

interface TableComponentProps {
  dados: InstrumentsTableData[];
  instruments: any;
  setEdit: React.Dispatch<React.SetStateAction<{ status: boolean; data: any }>>;
  setDeleting: React.Dispatch<
    React.SetStateAction<{ status: boolean; data: any }>
  >;
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  filterName: string | null;
  filterReading: InstrumentReadingTypeEnum | null;
  filterStatus: InstrumentStatusEnum | null;
  setFilterName: React.Dispatch<React.SetStateAction<string | null>>;
  setFilterReading: React.Dispatch<
    React.SetStateAction<InstrumentReadingTypeEnum | null>
  >;
  setFilterStatus: React.Dispatch<
    React.SetStateAction<InstrumentStatusEnum | null>
  >;
  selectedType: string;
}

export const TableReadingComponent = ({
  dados,
  instruments,
  setEdit,
  setDeleting,
  setShowEditModal,
  setShowDeleteModal,
  filterName,
  filterReading,
  filterStatus,
  setFilterName,
  setFilterReading,
  setFilterStatus,
  selectedType
}: TableComponentProps) => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const { t } = useTranslation();
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [showPopUpText, setShowPopUpText] = useState<string>('');
  const [popupPosition, setPopupPosition] = useState<PopupPositionType>({
    x: 0,
    y: 0
  });
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Monitoring');
  const allColumns = dados.reduce((columns, item) => {
    Object.keys(item).forEach((key) => {
      if (!columns.includes(key)) {
        columns.push(key);
      }
    });
    return columns;
  }, [] as string[]);

  if (userRoleInModule !== 'viewer') {
    allColumns.push(t('actions'));
  }

  const uniqueNames = [
    { label: t('all'), value: null, id: null },
    ...Array.from(
      new Set(
        instruments.map((item: any) => ({
          label: t(item.name),
          value: item.id,
          id: item.id
        }))
      )
    )
  ];

  const status = [
    { label: t('all'), value: null, id: null },
    ...Object.values(InstrumentStatusEnum).map((value) => ({
      id: value,
      label: t(value.toLocaleLowerCase()),
      value: value
    }))
  ];

  const readings = [
    { label: t('all'), value: null, id: null },
    ...Object.values(InstrumentReadingTypeEnum).map((value) => ({
      id: value,
      label: t(value),
      value: value
    }))
  ];

  function handlePopUpEnter(event: any, observation: string) {
    const cellRect = event.target.getBoundingClientRect();
    setPopupPosition({
      x: cellRect.left + window.scrollX,
      y: cellRect.top + window.scrollY - 40
    });
    setShowPopUp(true);
    setShowPopUpText(observation);
  }

  function handlePopUpLeave() {
    setPopupPosition({ x: 0, y: 0 });
    setShowPopUp(false);
    setShowPopUpText('');
  }

  return (
    <>
      <TableDivStyle>
        <TableHolderFilterAndTitle>
          <FilterTitle>{t('Filters')}</FilterTitle>
          <TableHolderFilter>
            <Select
              styles={{
                ...StyleInstrumentSelect,
                container: (provided) => ({
                  ...provided,
                  width: '200px'
                })
              }}
              isSearchable={false}
              placeholder={t('name')}
              hideSelectedOptions
              options={uniqueNames}
              onChange={(e: any) => {
                setFilterName(e.value);
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={uniqueNames.find((name: any) => name.label === filterName)}
            />
            <Select
              styles={{
                ...StyleInstrumentSelect,
                container: (provided) => ({
                  ...provided,
                  width: '200px'
                })
              }}
              isSearchable={false}
              placeholder={t('readingsType')}
              hideSelectedOptions
              options={readings}
              onChange={(e: any) => {
                setFilterReading(e.value);
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={readings.find((name: any) => name.label === filterReading)}
            />
            <Select
              styles={{
                ...StyleInstrumentSelect,
                container: (provided) => ({
                  ...provided,
                  width: '200px'
                })
              }}
              isSearchable={false}
              placeholder={t('status')}
              hideSelectedOptions
              options={status}
              onChange={(e: any) => {
                setFilterStatus(e.value);
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={status.find((name: any) => name.label === filterStatus)}
            />
          </TableHolderFilter>
        </TableHolderFilterAndTitle>
        <TableComponentStyle>
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{t('date')}</th>
              {allColumns.includes('isDry') && <th>{t('isDry')}</th>}
              {allColumns.includes('reading' && 'displacements') && (
                <th>{t('coordinateE')}</th>
              )}
              {allColumns.includes('reading' && 'displacements') && (
                <th>{t('coordinateN')}</th>
              )}
              {allColumns.includes('reading' && 'displacements') && (
                <th>{t('elevation')}</th>
              )}
              {allColumns.includes('reading' && 'displacements') && (
                <th>{t('operationalStatus')}</th>
              )}
              {allColumns.includes('reading' && 'cote') && (
                <th>{t('Reading')}</th>
              )}
              {allColumns
                .filter(
                  (column) =>
                    column !== 'readingsType' &&
                    column !== 'elevation' &&
                    column !== 'coordinateN' &&
                    column !== 'coordinateE' &&
                    column !== 'isDry' &&
                    column !== 'date' &&
                    column !== 'name' &&
                    column !== 'instrumentId' &&
                    column !== '__typename' &&
                    column !== 'associatedStructure' &&
                    column !== 'displacements' &&
                    column !== 'id' &&
                    column !== 'readingId' &&
                    column !== 'deactivationDate' &&
                    column !== 'activationDate' &&
                    column !== 'isUTM' &&
                    column !== 'reading'
                )
                .map((column) => {
                  return <th key={column}>{t(column)}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {dados.map((row: any, index) => {
              return (
                <tr
                  key={index}
                  onClick={() =>
                    setSelectedRow(index === selectedRow ? null : index)
                  }
                  style={
                    index === selectedRow
                      ? { backgroundColor: Color.Gray1 }
                      : {}
                  }
                >
                  <td>{row.name && <>{row.name}</>}</td>
                  <td>
                    {row.date ? (
                      <>{new Date(row.date).toLocaleString()}</>
                    ) : (
                      new Date(row.reading.date).toLocaleDateString()
                    )}
                  </td>
                  {row.reading && row.reading.coordinateE && (
                    <td>{row.reading.coordinateE}</td>
                  )}
                  {row.reading && row.reading.coordinateN && (
                    <td>{row.reading.coordinateN}</td>
                  )}
                  {row.reading && row.reading.elevation && (
                    <td>{row.reading.elevation}</td>
                  )}
                  {row.reading &&
                    row.operationalStatus &&
                    row.reading.coordinateE && (
                      <td>{t(row.operationalStatus.toLowerCase())}</td>
                    )}
                  {Object.keys(row)
                    .filter((key) => key === 'isDry')
                    .map((key, index) => {
                      return (
                        <td key={index}>
                          {row[key as keyof InstrumentsTableData]
                            ? t('Dry')
                            : t('WithReading')}
                        </td>
                      );
                    })}
                  {(selectedType === 'Piezometer' ||
                    selectedType === 'INA') && (
                    <td>
                      {row.reading === null ? (
                        <span>-</span>
                      ) : typeof row.reading === 'object' ? (
                        JSON.stringify(row.reading)
                      ) : (
                        row.reading
                      )}
                    </td>
                  )}
                  {Object.keys(row)
                    .filter(
                      (key) =>
                        key !== 'readingsType' &&
                        key !== 'elevation' &&
                        key !== 'coordinateN' &&
                        key !== 'coordinateE' &&
                        key !== 'isDry' &&
                        key !== 'date' &&
                        key !== 'name' &&
                        key !== 'instrumentId' &&
                        key !== '__typename' &&
                        key !== 'displacements' &&
                        key !== 'id' &&
                        key !== 'associatedStructure' &&
                        key !== 'readingId' &&
                        key !== 'deactivationDate' &&
                        key !== 'activationDate' &&
                        key !== 'isUTM' &&
                        key !== 'reading' &&
                        key in row
                    )
                    .map((key) => {
                      return (
                        <CellWithTooltip key={key}>
                          {key === 'observation' && row[key] !== undefined ? (
                            <>
                              <span
                                onMouseEnter={(event) =>
                                  handlePopUpEnter(event, row[key]!)
                                }
                                onMouseLeave={handlePopUpLeave}
                              >
                                {key === 'observation' &&
                                row[key] !== null &&
                                row[key]!.length > 0
                                  ? `${row[key]!.slice(0, 9)}...`
                                  : '-'}
                              </span>
                            </>
                          ) : (
                            t(row[key as keyof InstrumentsTableData]) || '-'
                          )}
                        </CellWithTooltip>
                      );
                    })}
                  {userRoleInModule !== 'viewer' && (
                    <td>
                      {row.operationalStatus === 'Active' ? (
                        <ActionsRow>
                          <BiSolidPencil
                            onClick={() => {
                              setEdit({ status: true, data: row });
                              setShowEditModal(true);
                            }}
                          />
                          <MdDelete
                            onClick={() => {
                              setDeleting({ status: true, data: row });
                              setShowDeleteModal(true);
                            }}
                          />
                        </ActionsRow>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </TableComponentStyle>
      </TableDivStyle>
      {showPopUp && (
        <PopUp popupPosition={popupPosition} showPopUpText={showPopUpText} />
      )}
    </>
  );
};
