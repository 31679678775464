import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import * as z from 'zod';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { ActionPlanPriorityEnum } from '../../../../data/graphql/base-schema';
import {
  AssociateActionPlansDocument,
  AssociateActionPlansMutation,
  AssociateActionPlansMutationVariables
} from '../../../../data/graphql/generated/associateActionPlans.mutation';
import {
  UpdateActionPlanDocument,
  UpdateActionPlanMutation,
  UpdateActionPlanMutationVariables
} from '../../../../data/graphql/generated/updateActionPlan.mutation';
import {
  FindAllActionPlansDocument,
  FindAllActionPlansQuery,
  FindAllActionPlansQueryVariables
} from '../../../../data/graphql/query/generated/findAllActionPlans.query';
import {
  FindActionPlanGeneratedDocument,
  FindActionPlanGeneratedQuery,
  FindActionPlanGeneratedQueryVariables
} from '../../../../data/services/ActionPlanGeneratedService';
import { FindActionPlanAllInfoDocument } from '../../../../data/services/ActionPlanService';
import { BUCKET_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { ModuleData } from '../../../Map/types';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { PictureGallery } from '../../Molecules/PictureGallery/PictureGallery';
import ActionPlanApprovalFlow from '../../Organisms/ActionPlanApprovalFlow/ActionPlanApprovalFlow';
import ActionPlanComments from '../../Organisms/ActionPlanComments/ActionPlanComments';
import ActionPlanReprovalFlow from '../../Organisms/ActionPlanReprovalFlow/ActionPlanReprovalFlow';
import FourStepsTemplate from '../../Templates/FourStepsTemplate/FourStepsTemplate';
import { OptionItem } from './ActionPlanGeneratedPage.interface';
import {
  DivButtonApproveFlow,
  DivButtonHeader,
  DivButtonsPictures,
  DivContentImageAndDescription,
  DivContainerCreateButton,
  DivContainerInputs,
  DivSaveButton,
  CardImages,
  CardDescription,
  HolderCardsTitle,
  CardInputs,
  HolderInputs,
  DivCommentsSection,
  CardSendComment,
  HolderComment
} from './ActionPlanGeneratedPage.styles';
import { t } from 'i18next';

const ActionPlanGeneratedPage = () => {
  const { t: translate } = useTranslation();
  const [isReproval, setIsReproval] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const { structureId, actionPlanId } = useParams();
  const navigate = useNavigate();
  const {
    modulesByStructure,
    userData: { userId }
  } = useContext(ProjectContext) as {
    modulesByStructure: ModuleData[];
    userData: { userId: string };
  };
  const modulePermission = modulesByStructure?.find(
    (module) => module.moduleName === 'ActionPlan'
  )?.role;
  const [approveData, setApproveData] = useState({
    timeFrame: new Date(),
    responsibleId: '',
    priority: ActionPlanPriorityEnum.Low
  });

  const [
    getActionPlanGeneratedData,
    { data: actionPlanGeneratedData, loading }
  ] = useLazyQuery<
    FindActionPlanGeneratedQuery,
    FindActionPlanGeneratedQueryVariables
  >(FindActionPlanGeneratedDocument, {
    variables: {
      actionPlanId: actionPlanId!,
      structureId: structureId!
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const [updateActionPlan] = useMutation<
    UpdateActionPlanMutation,
    UpdateActionPlanMutationVariables
  >(UpdateActionPlanDocument);

  const { data: actionPlansData } = useQuery<
    FindAllActionPlansQuery,
    FindAllActionPlansQueryVariables
  >(FindAllActionPlansDocument, {
    variables: {
      structureId: structureId!
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const [associateActionPlans] = useMutation<
    AssociateActionPlansMutation,
    AssociateActionPlansMutationVariables
  >(AssociateActionPlansDocument);

  const associatedActionPlans: OptionItem[] =
    actionPlansData?.findAllActionPlans.map((actionPlan) => ({
      label: actionPlan.code,
      value: actionPlan.id
    })) || [];

  const urlsPicturesData =
    actionPlanGeneratedData?.findActionPlanData.images.map((url) => ({
      url: `${BUCKET_URL}/${url}`,
      name: url,
      id: url,
      file: undefined
    }));

  const userResponsible =
    actionPlanGeneratedData?.findActionPlanData.responsible?.id === userId;

  const actionPlanConcluded =
    actionPlanGeneratedData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'done';

  const actionPlanCancelled =
    actionPlanGeneratedData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'cancelled';

  const actionPlanReproved =
    actionPlanGeneratedData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'reproved';

  const actionPlanOpen =
    actionPlanGeneratedData?.findActionPlanData.situation?.toLocaleLowerCase() ===
    'opened';

  const actionPlanSchema = z.object({
    priorities: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      }),
    timeFrame: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      }),
    responsible: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      })
  });

  type actionPlanSchemaType = z.infer<typeof actionPlanSchema>;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<actionPlanSchemaType>({
    resolver: zodResolver(actionPlanSchema),
    defaultValues: async () => {
      const responseGeneratedData = await getActionPlanGeneratedData({
        variables: {
          actionPlanId: actionPlanId!,
          structureId: structureId!
        }
      });

      if (
        !responseGeneratedData.data ||
        actionPlanGeneratedData?.findActionPlanData.situation?.toLocaleLowerCase() ===
          'opened'
      ) {
        return {
          priorities: {
            value: '',
            label: ''
          },
          timeFrame: new Date(),
          responsible: {
            value: '',
            label: ''
          }
        };
      }

      return {
        priorities: {
          value: responseGeneratedData.data.findActionPlanData.priority,
          label: translate(
            responseGeneratedData.data.findActionPlanData.priority
          )
        },
        timeFrame: new Date(
          responseGeneratedData.data.findActionPlanData.timeFrame || new Date()
        ),
        responsible: {
          value:
            responseGeneratedData.data?.findActionPlanData?.responsible?.id ||
            '',
          label:
            responseGeneratedData.data?.findActionPlanData?.responsible?.name ||
            ''
        }
      };
    }
  });

  const linkSchema = z.object({
    link: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      })
  });

  type linkSchemaType = z.infer<typeof linkSchema>;

  const {
    handleSubmit: handleSubmitLink,
    control: controlLink,
    formState: { errors: errorsLink, touchedFields }
  } = useForm<linkSchemaType>({
    resolver: zodResolver(linkSchema)
  });

  const priorityOptions = [
    {
      label: translate('High'),
      value: 'High'
    },
    {
      label: translate('Medium'),
      value: 'Medium'
    },
    {
      label: translate('Low'),
      value: 'Low'
    }
  ];

  const selectResponsibleData: OptionItem[] =
    actionPlanGeneratedData?.findActionPlanResponsible.map((responsible) => ({
      label: responsible.name,
      value: responsible.id
    })) ?? [];

  const handleSaveFlow = (data: actionPlanSchemaType) => {
    if (modulePermission === 'Admin') {
      updateActionPlan({
        variables: {
          data: {
            actionPlanId: actionPlanId ?? '',
            priority: data.priorities.value as ActionPlanPriorityEnum,
            timeFrame: data.timeFrame,
            responsibleId: data.responsible.value
          }
        },
        onCompleted: () => {
          toastfySuccess(
            `${translate('Action plan')} ${translate('editedSuccessfully')}`
          );
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindActionPlanGeneratedDocument,
            variables: {
              actionPlanId: actionPlanId ?? ''
            }
          }) as FindActionPlanGeneratedQuery | undefined;

          const updatedData = {
            ...existingData,
            findActionPlanData: {
              ...existingData?.findActionPlanData,
              priority: data?.updateActionPlan
            }
          };
          cache.writeQuery({
            query: FindActionPlanGeneratedDocument,
            variables: {
              actionPlanId: actionPlanId ?? ''
            },
            data: updatedData
          });
        }
      });
    }
  };

  const handleSaveAssociatedActionPlan = (data: linkSchemaType) => {
    associateActionPlans({
      variables: {
        parentId: data.link.value,
        actionPlanId: actionPlanId ?? ''
      },
      onCompleted: () => {
        toastfySuccess(
          `${translate('Action plan')} ${translate('associatedSuccessfully')}`
        );
        navigate(`/${structureId}/actionplan/`);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      },
      refetchQueries: [
        {
          query: FindActionPlanAllInfoDocument,
          variables: {
            pageInfo: {
              limit: 10,
              offset: 0,
              page: 0
            },
            structureId: structureId ?? '',
            filters: {
              code: null,
              priority: null,
              responsibleName: null,
              situation: null,
              timeFramePeriod: null,
              type: null
            },
            sortInfo: [
              {
                direction: null,
                field: ''
              }
            ]
          }
        }
      ],
      fetchPolicy: 'no-cache'
    });
  };

  const handleNavigateBack = () => {
    navigate(`/${structureId}/actionplan/`);
  };

  const handleApproveFlow = (data: actionPlanSchemaType) => {
    setIsApproval(true);
    setApproveData({
      timeFrame: data.timeFrame,
      responsibleId: data.responsible.value,
      priority: data.priorities.value as ActionPlanPriorityEnum
    });
  };

  return (
    <>
      <FourStepsTemplate
        loading={loading}
        icon={
          <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
        }
        title={`${actionPlanGeneratedData?.findActionPlanData.code}
        - ${translate(actionPlanGeneratedData?.findActionPlanData.situation?.toLocaleLowerCase())}`}
        button={
          <DivButtonHeader>
            <Button
              variant={'secondary'}
              text={translate('team')}
              size="small"
              onClick={() =>
                navigate(`/${structureId}/actionplan/${actionPlanId}/team`)
              }
            />
            <Button
              variant={'secondary'}
              text={translate('history')}
              size="small"
              onClick={() =>
                navigate(`/${structureId}/actionplan/${actionPlanId}/history`)
              }
            />
          </DivButtonHeader>
        }
        contentPictures={
          <DivContentImageAndDescription>
            <CardImages>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('picturesOfAnomaly')}</Text>
              </HolderCardsTitle>

              <PictureGallery
                imageWidth="80%"
                files={urlsPicturesData ?? []}
                imagesToShow={3}
              />
              {actionPlanGeneratedData &&
                actionPlanGeneratedData?.findActionPlanData.imagesCount > 3 && (
                  <DivButtonsPictures>
                    <Button
                      variant="secondary"
                      text={translate('seeAll')}
                      size="small"
                      onClick={() =>
                        navigate(
                          `/${structureId}/actionplan/${actionPlanId}/images`
                        )
                      }
                    />
                  </DivButtonsPictures>
                )}
            </CardImages>
            <CardDescription>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('description')}</Text>
              </HolderCardsTitle>
              <Text type={'span'} color="black">
                {actionPlanGeneratedData?.findActionPlanData.description}
              </Text>
            </CardDescription>
          </DivContentImageAndDescription>
        }
        inputs={
          <DivContainerInputs>
            <CardInputs>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('actionPlanManagement')}</Text>
              </HolderCardsTitle>
              <HolderInputs>
                <InputSelectSearch
                  errorMessage={errors.responsible?.message}
                  label={translate('responsible')}
                  name="responsible"
                  options={selectResponsibleData}
                  width="300px"
                  error={!!errors.responsible}
                  control={control}
                  disabled={
                    actionPlanConcluded ||
                    actionPlanCancelled ||
                    actionPlanReproved
                  }
                />
                <InputSelectSearch
                  errorMessage={errors.priorities?.message}
                  label={translate('priorities')}
                  name="priorities"
                  options={priorityOptions}
                  width="300px"
                  error={!!errors.priorities}
                  control={control}
                  disabled={
                    actionPlanConcluded ||
                    actionPlanCancelled ||
                    actionPlanReproved
                  }
                />
                {modulePermission === 'Admin' && actionPlanOpen && (
                  <InputSelectSearch
                    errorMessage={errorsLink.link?.message}
                    label={translate('link')}
                    name="link"
                    options={associatedActionPlans}
                    width="300px"
                    error={!!errorsLink.link}
                    control={controlLink}
                  />
                )}
                <DatepickerInput
                  label={translate('timeFrame')}
                  placeholder={translate('timeFrame')}
                  error={!!errors.timeFrame}
                  errorMessage={errors.timeFrame?.message}
                  name="timeFrame"
                  control={control}
                  time={false}
                  disabled={
                    actionPlanConcluded ||
                    actionPlanCancelled ||
                    actionPlanReproved
                  }
                />
              </HolderInputs>
              <DivSaveButton>
                <Button
                  variant={'primary'}
                  text="Save"
                  size="small"
                  onClick={handleSubmit(handleSaveFlow)}
                />
                {touchedFields.link && (
                  <Button
                    variant={'secondary'}
                    text="link"
                    size="small"
                    onClick={handleSubmitLink(handleSaveAssociatedActionPlan)}
                  />
                )}
              </DivSaveButton>
            </CardInputs>
          </DivContainerInputs>
        }
        commentContent={
          <DivCommentsSection>
            <CardSendComment>
              <HolderCardsTitle>
                <Text type={'h3'}>{translate('actionPlanComments')}</Text>
              </HolderCardsTitle>
              <HolderComment>
                <ActionPlanComments
                  disabled={
                    actionPlanConcluded ||
                    actionPlanCancelled ||
                    actionPlanReproved
                  }
                  actionPlanGeneratedData={actionPlanGeneratedData}
                />
              </HolderComment>
            </CardSendComment>
          </DivCommentsSection>
        }
        buttons={
          actionPlanConcluded || actionPlanCancelled || actionPlanReproved ? (
            <></>
          ) : (
            <DivContainerCreateButton>
              {modulePermission === 'Admin' && (
                <DivButtonApproveFlow>
                  <Button
                    variant={'secondary'}
                    text="approve"
                    size="small"
                    onClick={handleSubmit(handleApproveFlow)}
                  />
                  <Button
                    variant={'danger'}
                    text="reprove"
                    size="small"
                    onClick={() => setIsReproval(true)}
                  />
                </DivButtonApproveFlow>
              )}

              {isApproval && (
                <ActionPlanApprovalFlow
                  setIsApproval={setIsApproval}
                  situation={
                    actionPlanGeneratedData?.findActionPlanData.situation ?? ''
                  }
                  timeFrame={approveData?.timeFrame ?? new Date().toISOString()}
                  responsibleId={approveData?.responsibleId ?? ''}
                  priority={approveData?.priority ?? ' '}
                />
              )}

              {isReproval && (
                <ActionPlanReprovalFlow
                  setIsReproval={setIsReproval}
                  situation={
                    actionPlanGeneratedData?.findActionPlanData.situation ?? ''
                  }
                />
              )}

              {userResponsible ||
                (modulePermission !== 'Admin' && (
                  <Button
                    variant={'secondary'}
                    text="requestApproval"
                    size="large"
                    onClick={() => setIsApproval(true)}
                  />
                ))}
            </DivContainerCreateButton>
          )
        }
      />
    </>
  );
};

export default ActionPlanGeneratedPage;
