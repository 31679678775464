import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListPiezometersByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListPiezometersByStructureQuery = { __typename?: 'Query', listPiezometersByStructure: Array<{ __typename?: 'ListPiezometerInaByStructure', readingType: string, installLocation?: string | null, status: boolean, operationalStatus: Types.InstrumentStatusEnum, coordinateE: number, coordinateN: number, isUTM: boolean, activationDate?: Date | null, deactivationDate?: Date | null, installationDate?: Date | null, id: string, name: string, type: { __typename?: 'BasicInstrumentTypeType', id: string, instrumentType: string } }> };


export const ListPiezometersByStructureDocument = gql`
    query ListPiezometersByStructure($structureInfo: StructureInfoInput!) {
  listPiezometersByStructure(structureInfo: $structureInfo) {
    type {
      id
      instrumentType
    }
    readingType
    installLocation
    status
    operationalStatus
    coordinateE
    coordinateN
    isUTM
    activationDate
    deactivationDate
    installationDate
    id
    name
  }
}
    `;
export type ListPiezometersByStructureQueryResult = Apollo.QueryResult<ListPiezometersByStructureQuery, ListPiezometersByStructureQueryVariables>;