import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteActionPlanTeamMemberDocument,
  DeleteActionPlanTeamMemberMutation,
  DeleteActionPlanTeamMemberMutationVariables
} from '../../../../data/graphql/generated/deleteActionPlanTeamMember.mutation';
import {
  FindActionPlanGeneratedDocument,
  FindActionPlanGeneratedQuery,
  FindActionPlanGeneratedQueryVariables
} from '../../../../data/services/ActionPlanGeneratedService';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ActionPlanSaveMember from '../../Organisms/ActionPlanApprovalFlow copy/ActionPlanSaveMember';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { DeleteTeamMember } from './ActionPlanTeamPage.interface';
import {
  DivContentTeamMembers,
  DivTableTeam,
  DivTitle
} from './ActionPlanTeamPage.styles';

const ActionPlanTeamPage = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { structureId, actionPlanId } = useParams();
  const [onSearch, setOnSearch] = useState('');
  const columns = [
    { key: 'name', label: translate('name') },
    { key: 'email', label: translate('email') }
  ];
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<DeleteTeamMember>({
    id: '',
    isOpen: false
  });

  const { data: dataMembers, loading: loadingMembers } = useQuery<
    FindActionPlanGeneratedQuery,
    FindActionPlanGeneratedQueryVariables
  >(FindActionPlanGeneratedDocument, {
    variables: {
      actionPlanId: actionPlanId!,
      structureId: structureId!
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const [deleteActionPlanTeamMember] = useMutation<
    DeleteActionPlanTeamMemberMutation,
    DeleteActionPlanTeamMemberMutationVariables
  >(DeleteActionPlanTeamMemberDocument);

  const tableMembersData = dataMembers?.findActionPlanData.teamMembers.map(
    (item) => ({
      id: item.id,
      name: item.name,
      email: item.email
    })
  );

  const filteredMembersData = tableMembersData?.filter(
    (item) =>
      item.name.toLowerCase().includes(onSearch) ||
      item.email.toLowerCase().includes(onSearch)
  );

  const handleDeleteMember = (id: string) => {
    deleteActionPlanTeamMember({
      variables: {
        actionPlanId: actionPlanId!,
        teamMemberId: id
      },
      onCompleted: () => {
        setDeleteModalIsOpen({ id: '', isOpen: false });
        toastfySuccess(
          `${translate('member')} ${translate('successfullyDeactivated')}`
        );
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setDeleteModalIsOpen({ id: '', isOpen: false });
      },
      refetchQueries: [FindActionPlanGeneratedDocument]
    });
  };

  const handleNavigateBack = () => {
    navigate(`/${structureId}/actionplan/${actionPlanId}`);
  };

  return (
    <SimpleContentTemplate
      loading={loadingMembers}
      title={
        <DivTitle>
          <Icon Icon={IoIosArrowBack} onClick={() => handleNavigateBack()} />
          <Text weight="bold" type="h2">
            {translate('team')}
          </Text>
        </DivTitle>
      }
      button={
        <Button
          variant={'primary'}
          text={`${translate('link')} ${translate('team')}`}
          size="medium"
          onClick={() => setSaveModalIsOpen(true)}
        />
      }
      content={
        <DivContentTeamMembers>
          <DivTableTeam>
            <TableComponent
              columns={columns}
              onSearch={(e) => setOnSearch(e.toLowerCase())}
              tableData={filteredMembersData || []}
              actions={[
                {
                  name: `${translate('Delete')}`,
                  icon: <MdDelete />,
                  onClick: (element: any) =>
                    setDeleteModalIsOpen({
                      id: element.id,
                      isOpen: true
                    }),
                  canShow: 'canDelete'
                }
              ]}
            />
          </DivTableTeam>
          {saveModalIsOpen && (
            <ActionPlanSaveMember setSaveModalIsOpen={setSaveModalIsOpen} />
          )}

          {deleteModalIsOpen.isOpen && (
            <ModalConfirm
              onClose={() => setDeleteModalIsOpen({ id: '', isOpen: false })}
              onConfirm={() => handleDeleteMember(deleteModalIsOpen.id)}
              title={`${translate('Delete')} ${translate('member')}`}
              text={translate('deleteMemberText')}
            />
          )}
        </DivContentTeamMembers>
      }
    />
  );
};

export default ActionPlanTeamPage;
