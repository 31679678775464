import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../../Context/ContextAPI';
import ExportData from '../../../Controllers/Export/ExportData';
import ReadingsController from '../../../Controllers/Instruments/ReadingsController';
import InstrumentsModel from '../../../Models/InstrumentsModel';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../data/graphql/base-schema';
import { useListPiezometersByStructure } from '../../../data/hooks/piezometer/use-list-piezometers-by-structure.query';
import { useListReadingByPiezometerPagLazy } from '../../../data/hooks/piezometer/use-list-reading-by-piezometer-pag';
import { useListPluviometersByStructure } from '../../../data/hooks/pluviometer/use-list-pluviometers-by-structure';
import { useListReadingByPluviometerPagLazy } from '../../../data/hooks/pluviometer/use-list-reading-by-pluviometer-pag';
import { useListReadingBySurfaceMarkersPagLazy } from '../../../data/hooks/surface-marker/use-list-reading-by-surface-markers-pag';
import { useListSurfaceMarkersByStructure } from '../../../data/hooks/surface-marker/use-list-surface-markers-by-structure.query';
import { useListReadingByWaterLevelPagLazy } from '../../../data/hooks/water-level/use-list-reading-by-water-level-pag';
import { useListWaterLevelByStructure } from '../../../data/hooks/water-level/use-list-water-level-by-structure';
import { DefaultButtonStyle } from '../../Buttons/ButtonsStyle';
import { DefaultButton } from '../../Buttons/DefaultButton';
import { DatePickerComponent } from '../../DatePicker';
import GraphHeader from '../../Graphs/Monitoring/Instrument/GraphHeader/GraphHeader';
import { AsideInstrumentsMenu } from '../../Menus/AsideInstrumentsMenu';
import { TableReadingComponent } from '../../Tables/TableReadingComponent';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Toastify';
import {
  DataBaseButtonArea,
  DateFilterArea,
  FilterBotton,
  HolderFilterAndTable,
  HolderOverflowTable,
  HolderPage,
  PagesButtonArea
} from './DataBaseStyles';
import { DeleteReadingsModal } from './Delete/DeleteReadingsModal';
import { EditReadingsModal } from './Edit/EditReadingsModal';
import { RegisterBatchReadingsModal } from './Register/RegisterBatchReadingsModal';
import { RegisterReadingsModal } from './Register/RegisterReadingsModal';
import InputText from '../../V2/Molecules/InputText/InputText';
import { useDebounceCallback } from 'usehooks-ts';

export const ReadingsComponent = () => {
  const { structureId } = useParams();
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });

  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState<string>(
    InstrumentsModel().types[0].id
  );

  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [data, setData] = useState<any>([]);
  const [instruments, setInstruments] = useState<any>([]);
  const [createdReading, setCreatedReading] = useState<boolean>(false);

  const numberOfItensPerPage =
    window.innerHeight <= 730
      ? Math.floor(window.innerHeight / 110)
      : Math.floor(window.innerHeight / 80);

  const [countPie, setCountPie] = useState<number>(0);
  const [countPlu, setCountPlu] = useState<number>(0);
  const [countWL, setCountWL] = useState<number>(0);
  const [countSM, setCountSM] = useState<number>(0);

  const [showRegisterModal, setShowRegisterModal] = useState<boolean>(false);
  const [showBatchRegisterModal, setShowBatchRegisterModal] =
    useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string | null>(null);
  const [filterReading, setFilterReading] =
    useState<InstrumentReadingTypeEnum | null>(null);
  const [filterStatus, setFilterStatus] = useState<InstrumentStatusEnum | null>(
    null
  );
  const { listReadingPiezometer } = useListReadingByPiezometerPagLazy();
  const { listReadingPluviometer } = useListReadingByPluviometerPagLazy();
  const { listReadingWaterLevel } = useListReadingByWaterLevelPagLazy();
  const { listReadingSurfaceMarker } = useListReadingBySurfaceMarkersPagLazy();
  const [allData, setAllData] = useState<boolean>(false);

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Monitoring');
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };

  const { data: listAllPiezometers } =
    useListPiezometersByStructure(structureInfo);
  const { data: listAllPluviometers } =
    useListPluviometersByStructure(structureInfo);
  const { data: listAllWaterLevel } =
    useListWaterLevelByStructure(structureInfo);
  const { data: listAllSurfaceMarker } =
    useListSurfaceMarkersByStructure(structureInfo);

  useEffect(() => {
    handleFetchTableData(selectedType);
  }, [
    selectedType,
    currentPage,
    filterStatus,
    filterName,
    filterReading,
    startDate,
    endDate,
    createdReading,
    allData
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    filterStatus,
    filterName,
    filterReading,
    startDate,
    endDate,
    selectedType
  ]);

  function handleDateFilter() {
    setStartDate(null);
    setEndDate(null);
  }

  async function handlePiezometer(key: any) {
    const formattedKey =
      key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();

    const instrumentTypeEnum =
      formattedKey === 'Piezometer'
        ? InstrumentTypeEnum.Piezometer
        : InstrumentTypeEnum.Ina;

    if (allData) {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingPiezometer({
          variables: {
            structureInfo,
            pageInfo: { limit: numberOfItensPerPage, page: currentPage },
            instrumentType: instrumentTypeEnum
          }
        });
        if (response.data) {
          ExportData.exportReadingsList(
            response.data.listReadingByPiezometersPag.nodes,
            selectedType
          );
          setAllData(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    } else {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingPiezometer({
          variables: {
            structureInfo,
            pageInfo: {
              limit: numberOfItensPerPage,
              page: currentPage
            },
            filters: {
              startDate: endDate && startDate ? startDate : null,
              endDate: endDate && startDate ? endDate : null,
              status: filterStatus,
              readingType: filterReading ?? null,
              instrumentsId: filterName ? [filterName] : null
            },
            instrumentType: instrumentTypeEnum
          }
        });

        if (response.data) {
          setInstruments(
            listAllPiezometers?.listPiezometersByStructure.filter(
              (item) =>
                item.type.instrumentType.toLocaleLowerCase() ===
                key.toLowerCase()
            ) ?? []
          );
          setData(response.data.listReadingByPiezometersPag.nodes);
          if (countPie === 0) {
            setTotalPages(
              response.data.listReadingByPiezometersPag.pageInfo.totalPages
            );
            setCountPie(response.data.listReadingByPiezometersPag.count);
          } else {
            setTotalPages(
              Math.ceil(
                response.data.listReadingByPiezometersPag.count /
                  numberOfItensPerPage
              )
            );
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    }
  }

  async function handlePluviometer() {
    if (allData) {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingPluviometer({
          variables: {
            structureInfo
          }
        });
        if (response.data) {
          ExportData.exportReadingsList(
            response.data.listReadingByPluviometerPag.nodes,
            selectedType
          );
          setAllData(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    } else {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingPluviometer({
          variables: {
            structureInfo,
            pageInfo: {
              limit: numberOfItensPerPage,
              page: currentPage
            },
            filters: {
              startDate: endDate && startDate ? startDate : null,
              endDate: endDate && startDate ? endDate : null,
              status: filterStatus,
              readingType: filterReading,
              instrumentsId: filterName ? [filterName] : null
            }
          }
        });

        if (response.data) {
          setInstruments(
            listAllPluviometers?.listPluviometersByStructure ?? []
          );
          setData(response.data.listReadingByPluviometerPag.nodes);
          if (countPlu === 0) {
            setTotalPages(
              response.data.listReadingByPluviometerPag.pageInfo.totalPages
            );
            setCountPlu(response.data.listReadingByPluviometerPag.count);
          } else {
            setTotalPages(
              Math.ceil(
                response.data.listReadingByPluviometerPag.count /
                  numberOfItensPerPage
              )
            );
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    }
  }

  async function handleWaterLevel() {
    if (allData) {
      try {
        toastifyLoading(t('loading'));

        const response = await listReadingWaterLevel({
          variables: {
            structureInfo
          }
        });
        if (response.data) {
          ExportData.exportReadingsList(
            response.data.listReadingByWaterLevelPag.nodes,
            selectedType
          );
          setAllData(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    } else {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingWaterLevel({
          variables: {
            structureInfo,
            pageInfo: {
              limit: numberOfItensPerPage,
              page: currentPage
            },
            filters: {
              startDate: endDate && startDate ? startDate : null,
              endDate: endDate && startDate ? endDate : null,
              status: filterStatus,
              readingType: filterReading,
              instrumentsId: filterName ? [filterName] : null
            }
          }
        });
        if (response.data) {
          toastfyDimiss('toastLoading');
          setInstruments(listAllWaterLevel?.listWaterLevelByStructure ?? []);
          setData(response.data.listReadingByWaterLevelPag.nodes);
          if (countWL === 0) {
            setTotalPages(
              response.data.listReadingByWaterLevelPag.pageInfo.totalPages
            );
            setCountWL(response.data.listReadingByWaterLevelPag.count);
          } else {
            setTotalPages(
              Math.ceil(
                response.data.listReadingByWaterLevelPag.count /
                  numberOfItensPerPage
              )
            );
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    }
  }

  async function handleSurfaceMarker() {
    if (allData) {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingSurfaceMarker({
          variables: {
            structureInfo
          }
        });
        if (response.data) {
          toastfyDimiss('toastLoading');
          ExportData.exportReadingsList(
            response.data.listReadingBySurfaceMarkersPag.nodes,
            selectedType
          );
          setAllData(false);
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    } else {
      try {
        toastifyLoading(t('loading'));
        const response = await listReadingSurfaceMarker({
          variables: {
            structureInfo,
            pageInfo: {
              limit: numberOfItensPerPage,
              page: currentPage
            },
            filters: {
              startDate: endDate && startDate ? startDate : null,
              endDate: endDate && startDate ? endDate : null,
              status: filterStatus,
              readingType: filterReading,
              instrumentsId: filterName ? [filterName] : null
            }
          }
        });
        if (response.data) {
          toastfyDimiss('toastLoading');
          setInstruments(
            listAllSurfaceMarker?.listSurfaceMarkersByStructure ?? []
          );
          setData(response.data.listReadingBySurfaceMarkersPag.nodes);
          if (countSM === 0) {
            setTotalPages(
              response.data.listReadingBySurfaceMarkersPag.pageInfo.totalPages
            );
            setCountSM(response.data.listReadingBySurfaceMarkersPag.count);
          } else {
            setTotalPages(
              Math.ceil(
                response.data.listReadingBySurfaceMarkersPag.count /
                  numberOfItensPerPage
              )
            );
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        toastfyDimiss('toastLoading');
        toastfySuccess(t('infoLoadedSuccessfully'));
      }
    }
  }

  function handleFetchTableData(key: any) {
    const strategy = {
      piezometer: () => handlePiezometer(key),
      pluviometer: () => handlePluviometer(),
      ina: () => handlePiezometer(key),
      waterlevel: () => handleWaterLevel(),
      surfacemarker: () => handleSurfaceMarker()
    };

    strategy[key.toLowerCase() as keyof typeof strategy]();
  }

  function handlePreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((state) => state - 1);
    }
  }

  const handleSelectPage = useDebounceCallback((page: number) => {
    if (page <= totalPages && page > 0) {
      setCurrentPage(page);
    } else {
      setCurrentPage(1);
      toastfyError(t('pageNotFound'));
    }
  }, 600);

  function handleNextPage() {
    if (currentPage < totalPages) {
      setCurrentPage((state) => state + 1);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff'
      }}
    >
      <GraphHeader
        showModal={showModal}
        setShowModal={setShowModal}
        title="Reading"
      />
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          top: '78px',
          bottom: 0,
          width: '100%',
          maxWidth: '100%'
        }}
      >
        <AsideInstrumentsMenu
          selectedItem={selectedType}
          setSelectedItem={setSelectedType}
          title="Instruments"
          items={InstrumentsModel().types}
        />
        <HolderPage>
          <HolderFilterAndTable>
            <HolderOverflowTable>
              <TableReadingComponent
                dados={data}
                setEdit={setEdit}
                setShowEditModal={setShowEditModal}
                setShowDeleteModal={setShowDeleteModal}
                setDeleting={setDeleting}
                filterName={filterName}
                filterReading={filterReading}
                filterStatus={filterStatus}
                setFilterName={setFilterName}
                setFilterReading={setFilterReading}
                setFilterStatus={setFilterStatus}
                instruments={instruments}
                selectedType={selectedType}
              />
            </HolderOverflowTable>
            <FilterBotton>
              <DateFilterArea>
                <DefaultButtonStyle
                  style={{ width: '100px' }}
                  onClick={() => {
                    handleDateFilter();
                  }}
                >
                  {t('clean')}
                </DefaultButtonStyle>
                <DatePickerComponent
                  setStartDate={setStartDate}
                  startDate={startDate}
                  hasInterval
                  setEndDate={setEndDate}
                  endDate={endDate}
                  dateText={t('InitialDate')}
                />
              </DateFilterArea>
            </FilterBotton>
            <PagesButtonArea>
              <MdKeyboardArrowLeft onClick={handlePreviousPage} />
              <span>
                {currentPage} de {totalPages}
              </span>
              <MdKeyboardArrowRight onClick={handleNextPage} />
              <InputText
                label={t('goTo')}
                type="number"
                width="50px"
                name="goToPage"
                value={`${currentPage}`}
                onChange={(event) =>
                  handleSelectPage(Number(event.target.value))
                }
              />
            </PagesButtonArea>
          </HolderFilterAndTable>
        </HolderPage>
      </div>
      <DataBaseButtonArea>
        {userRoleInModule !== 'viewer' && (
          <DefaultButton
            title="RegisterReadings"
            action={() =>
              ReadingsController.ShowModal({
                showModal: showRegisterModal,
                setShowModal: setShowRegisterModal
              })
            }
          />
        )}
        {userRoleInModule !== 'viewer' && (
          <DefaultButton
            title="RegisterReadingsFile"
            action={() => {
              ReadingsController.ShowModal({
                showModal: showBatchRegisterModal,
                setShowModal: setShowBatchRegisterModal
              });
            }}
          />
        )}
        {/* button comentado para implementação de do endpoint de exportação no back */}
        {/* <DefaultButton
          title="ExportTable"
          action={() => {
            setAllData(true);
            handleFetchTableData(selectedType);
          }}
        /> */}
      </DataBaseButtonArea>

      {showRegisterModal ? (
        <RegisterReadingsModal
          type={selectedType}
          showModal={showRegisterModal}
          setShowModal={setShowRegisterModal}
          createdReading={createdReading}
          setCreatedReading={setCreatedReading}
          setCountPie={setCountPie}
          setCountPlu={setCountPlu}
          setCountWL={setCountWL}
          setCountSM={setCountSM}
        />
      ) : null}

      {showBatchRegisterModal ? (
        <RegisterBatchReadingsModal
          type={selectedType}
          showModal={showBatchRegisterModal}
          setShowModal={setShowBatchRegisterModal}
          createdReading={createdReading}
          setCreatedReading={setCreatedReading}
          setCountPie={setCountPie}
          setCountPlu={setCountPlu}
          setCountWL={setCountWL}
          setCountSM={setCountSM}
        />
      ) : null}

      {showEditModal && (
        <EditReadingsModal
          edit={edit}
          selectedType={selectedType}
          createdReading={createdReading}
          setCreatedReading={setCreatedReading}
          setShowModal={setShowEditModal}
          showModal={showEditModal}
        />
      )}

      {showDeleteModal && (
        <DeleteReadingsModal
          createdReading={createdReading}
          setCreatedReading={setCreatedReading}
          deleting={deleting}
          selectedType={selectedType}
          setShowModal={setShowDeleteModal}
          showModal={showDeleteModal}
          setData={setData}
          data={data}
          setCountPie={setCountPie}
          setCountPlu={setCountPlu}
          setCountWL={setCountWL}
          setCountSM={setCountSM}
        />
      )}
    </div>
  );
};
