import styled from 'styled-components';

export const DivButtonHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HolderCardsTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DivContentImageAndDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
  gap: 20px;
`;

export const CardImages = styled.div`
  padding: 0px 15px 15px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

export const CardDescription = styled.div`
  display: flex;
  padding: 0px 15px 15px 15px;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

export const DivContainerInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const CardInputs = styled.div`
  display: flex;
  padding: 0px 15px 15px 15px;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

export const HolderInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DivCommentsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

export const CardSendComment = styled.div`
  display: flex;
  padding: 0px 15px 15px 15px;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
  box-shadow:
    rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;

export const HolderComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DivSaveButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 15px;
`;

export const DivContainerCreateButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 50px 0;
`;

export const DivButtonApproveFlow = styled.div`
  display: flex;
  align-items: center;
`;

export const DivButtonSeeAll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const DivButtonsPictures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  width: 100%;
`;
