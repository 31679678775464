import React from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  EditPiezometerControlCoteDocument,
  EditPiezometerControlCoteMutation,
  EditPiezometerControlCoteMutationVariables
} from '../../../../data/graphql/generated/editPiezometerControlCote.mutation';
import { ListControlQuotasByPiezometersPagDocument } from '../../../../data/graphql/query/generated/listControlQuotasByPiezometersPag.query';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import InputText from '../../Molecules/InputText/InputText';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import Button from '../../Atoms/Button/Button';
import { EditPiezometerAndInaControlQuotasProps } from './EditPiezometerAndInaControlQuotas.interface';
import {
  DivContainer,
  DivInput,
  DivInputs
} from './EditPiezometerAndInaControlQuotas.styles';
import { controlQuotasDataProps } from '../../Pages/EditPiezometerAndInaPage/EditPiezometerAndInaPage.interface';

const EditPiezometerAndInaControlQuotas = ({
  data: { id, topCote, bottomCote, referenceDate },
  setModalControlQuotas
}: EditPiezometerAndInaControlQuotasProps<controlQuotasDataProps>) => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentId } = useParams();
  const controlQuotasSchema = z.object({
    topCote: z.string().min(0, translate('fillRequiredFields')),
    bottomCote: z.string().min(0, translate('fillRequiredFields')),
    referenceDate: z
      .date()
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fillRequiredFields')
      })
  });

  type controlQuotasType = z.infer<typeof controlQuotasSchema>;

  const [editControlQuotas] = useMutation<
    EditPiezometerControlCoteMutation,
    EditPiezometerControlCoteMutationVariables
  >(EditPiezometerControlCoteDocument);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<controlQuotasType>({
    resolver: zodResolver(controlQuotasSchema),
    defaultValues: {
      topCote: topCote.toString(),
      bottomCote: bottomCote.toString(),
      referenceDate: new Date(referenceDate)
    }
  });

  const handleQuotasControl = (dataFromForm: controlQuotasType) => {
    if (id) {
      editControlQuotas({
        variables: {
          data: {
            topCote: parseFloat(dataFromForm.topCote),
            referenceDate: dataFromForm.referenceDate,
            bottomCote: parseFloat(dataFromForm.bottomCote),
            instrumentId: instrumentId!,
            controlId: id
          },
          structureInfo: {
            structureId: structureId!,
            associatedStructureId: ''
          }
        },
        refetchQueries: [ListControlQuotasByPiezometersPagDocument],
        onCompleted: () => {
          toastfySuccess(translate('editedSuccessfully'));
          setModalControlQuotas({
            show: false,
            data: {
              id: '',
              topCote: 0,
              bottomCote: 0,
              referenceDate: new Date()
            }
          });
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        }
      });
    }
  };

  return (
    <DivContainer>
      <DivInputs>
        <DivInput>
          <InputText
            width="300px"
            control={control}
            label={translate('topCote')}
            name={'topCote'}
            type={'number'}
            error={!!errors.topCote}
            errorMessage={errors?.topCote?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            width="300px"
            control={control}
            label={translate('bottomCote')}
            name={'bottomCote'}
            type={'number'}
            error={!!errors.bottomCote}
            errorMessage={errors?.bottomCote?.message}
          />
        </DivInput>
        <DivInput>
          <DatepickerInput
            control={control}
            label={translate('ReferenceDate')}
            name="referenceDate"
            placeholder={translate('NoInformated')}
            time={false}
            error={!!errors.referenceDate}
            errorMessage={errors?.referenceDate?.message}
          />
        </DivInput>
      </DivInputs>
      <Button
        variant="primary"
        size="large"
        onClick={handleSubmit(handleQuotasControl)}
        text={translate('saveControlCotes')}
      />
    </DivContainer>
  );
};

export default EditPiezometerAndInaControlQuotas;
