import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ExportData from '../../../../Controllers/Export/ExportData';
import {
  ActionPlanFilterInput,
  ActionPlanPriorityEnum,
  ActionPlanSituationEnum,
  ActionPlanTypeEnum,
  SortDirection
} from '../../../../data/graphql/base-schema';
import {
  FindActionPlanAllInfoDocument,
  FindActionPlanAllInfoQuery,
  FindActionPlanAllInfoVariables
} from '../../../../data/services/ActionPlanService';
import { Color } from '../../../../Styles/Styles';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { OptionType } from '../../Molecules/Paginator/Paginator.interfaces';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import ActionPLanFilterComponent from '../../Organisms/ActionPlanFilters/ActionPlanFilters';
import CreateActionPlan from '../../Organisms/CreateActionPlan/CreateActionPlan';
import CardTableTemplate from '../../Templates/CardTableTemplate/CardTableTemplate';

const ActionPlan = () => {
  const { t: translate } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { structureId } = useParams();
  const columns = [
    { key: 'name', label: translate('name') },
    { key: 'responsible', label: translate('responsible') },
    { key: 'status', label: translate('status') },
    { key: 'timeFrame', label: translate('timeFrame') },
    { key: 'priority', label: translate('priorities') }
  ];
  const [searchParams] = useSearchParams();
  const generalSearchString = searchParams.get('generalSearchString') || '';

  const filters: ActionPlanFilterInput = {
    code: searchParams.get('code') || null,
    priority: (searchParams.get('priority') as ActionPlanPriorityEnum) || null,
    responsibleName: searchParams.get('responsibleName') || null,
    situation:
      (searchParams.get('situation') as ActionPlanSituationEnum) || null,
    timeFramePeriod: searchParams.get('timeFramePeriod')
      ? [new Date(searchParams.get('timeFramePeriod') as string)]
      : null,
    type: (searchParams.get('type') as ActionPlanTypeEnum) || null
  };

  const { data: ActionPlanData, loading } = useQuery<
    FindActionPlanAllInfoQuery,
    FindActionPlanAllInfoVariables
  >(FindActionPlanAllInfoDocument, {
    variables: {
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      structureId: structureId ?? '',
      filters: filters,
      sortInfo: [
        {
          direction:
            searchParams.get('sortDirection') === 'ascending'
              ? SortDirection.ASC
              : SortDirection.DESC,
          field: searchParams.get('sortKey') || 'date'
        }
      ]
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });
  const totalPages = ActionPlanData?.findActionPlanPag.pageInfo.totalPages || 0;

  const handleNavigate = (row: any) => {
    navigate(`/${structureId}/actionplan/${row.id}`);
  };

  const tableActionPlanData = ActionPlanData?.findActionPlanPag.nodes.map(
    (item) => ({
      name: translate(item.name),
      responsible: translate(item.responsible),
      status: translate(item.status.toLocaleLowerCase()),
      timeFrame: new Date(item.timeFrame).toLocaleDateString('pt-BR') || '',
      priority: translate(item.priority),
      id: item.id,
      color:
        item.status === 'Concluded'
          ? Color.Green1
          : item.status === 'Late'
            ? Color.Red1
            : item.status === 'Opened'
              ? Color.Golden2
              : ''
    })
  );

  const filteredAtoData = !generalSearchString
    ? tableActionPlanData
    : tableActionPlanData?.filter((row) =>
        Object.values(row).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(generalSearchString.toLowerCase())
        )
      );

  return (
    <>
      <CardTableTemplate
        loading={loading}
        title={<Text type="h1">{translate('Action plan')}</Text>}
        button={
          <Button
            onClick={() => setIsModalOpen(true)}
            text={`${translate('create')} ${translate('Action plan')}`}
            size="large"
            variant="primary"
          />
        }
        cards={
          <>
            <SquareCard
              label={translate('total')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlans.toString() ?? ''
              }
            />
            <SquareCard
              label={translate('completed')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansDone.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('highPriority')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansHighPriority.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('inProgress')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansInProgress.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('overdue')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansLate.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('opened')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansOpened.toString() ??
                ''
              }
            />
          </>
        }
        content={
          <TableComponent
            columns={columns}
            filterComponent={<ActionPLanFilterComponent />}
            onRow={handleNavigate}
            tableData={filteredAtoData ?? []}
            paginator
            totalPages={totalPages}
            onSearch={() => {}}
            showInUrl={true}
            exportTable={(fields: any) =>
              ExportData.exportActionPlanList(
                ActionPlanData?.findActionPlanPag.nodes,
                fields
              )
            }
          />
        }
      />
      {isModalOpen && (
        <ViewModal
          title={`${translate('create')} ${translate('Action plan')}`}
          onClose={() => setIsModalOpen(false)}
          width="auto"
          height="auto"
          component={<CreateActionPlan onClose={() => setIsModalOpen(false)} />}
        />
      )}
    </>
  );
};

export default ActionPlan;
