import { useContext, useEffect, useState } from 'react';
import { AnswerEnum } from '../../../../data/graphql/base-schema';
import {
  RadioCheboxArea,
  RadioCheck,
  RadioCheckbox
} from '../../../../Styles/Styles';
import {
  InputFile,
  InspectionInputsDiv
} from '../../../../Components/Forms/FormInspectionsStyle';
import { DefaultInput } from '../../../../Components/Inputs/InputsStyle';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { toastfyError } from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { API_URL, BUCKET_URL } from '../../../../utils/const';
import FileList from '../../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';

export function YesNoBodyTable({
  setState,
  state,
  topic,
  title,
  error,
  inspectionId
}: {
  setState: any;
  state: any;
  topic: any;
  title: string;
  error: boolean;
  inspectionId: string;
}) {
  const [checkBox, setCheckBox] = useState([
    { checked: false },
    { checked: false }
  ]);
  const [observationArea, setObservationArea] = useState('');
  const [img, setImg] = useState<FileType[]>([]);
  const { t }: any = useTranslation();

  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const listTopicsNeedNo = ['Medidor de vazão operante'];

  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: userData.token
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  useEffect(() => {
    if (state.length > 0) {
      state.map((item: any) => {
        if (item.topic === topic) {
          setCheckBox([
            { checked: item.answer === AnswerEnum.Yes },
            { checked: item.answer === AnswerEnum.No }
          ]);
          setObservationArea(item.observation);
          if (item.file?.length > 0) {
            const fileURLs: any[] = [];
            item.file.map((image: any) => {
              if (image.image) {
                fileURLs.push({
                  id: image.id,
                  name: image.image,
                  url: `${BUCKET_URL}/${image.image}`
                });
              } else {
                fileURLs.push(image);
              }
            });
            setImg(fileURLs);
          }
        }
      });
    }
  }, [state]);

  const handleChange = (value: AnswerEnum, topic: string) => {
    const updatedStepStates = state.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, answer: value };
      }

      return item;
    });
    setState(updatedStepStates);
    setCheckBox([
      { checked: value === AnswerEnum.Yes },
      { checked: value === AnswerEnum.No }
    ]);
  };

  const handleChangeObservation = (value: string) => {
    const updatedState = state.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, observation: value };
      }

      return item;
    });
    setState(updatedState);
    setObservationArea(value);
  };

  const hanldeFileChange = (files: any) => {
    const updatedState = state.map((item: any) => {
      const existingFiles = Array.isArray(item.file) ? item.file : [];
      if (item.topic === topic) {
        return {
          ...item,
          fileSent: true,
          file: [...existingFiles, ...files]
        };
      }

      return item;
    });
    setState(updatedState);
    const updatedImg = img.concat(
      Array.from(files).map((file: any) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file)
        };
      })
    );
    setImg(updatedImg);
  };

  const handleDeleteFile = async (imgSelect: FileType) => {
    if (imgSelect.id) {
      const deleteResponse = await client.mutate({
        mutation: gql`
          mutation DeleteInspectionDataImage(
            $image: DeleteInspectionDataImageInput!
            $inspectionId: String!
          ) {
            deleteInspectionDataImage(
              image: $image
              inspectionId: $inspectionId
            )
          }
        `,
        variables: {
          inspectionId: inspectionId,
          image: {
            id: imgSelect.id,
            dataType: 'mssGenericData'
          }
        }
      });
      if (deleteResponse.data) {
        const filteredImages = img.filter(
          (image: FileType) => image.id !== imgSelect.id
        );
        setImg(filteredImages);
        const updatedStepStates = state.map((item: any) => {
          if (item.topic === topic) {
            return { ...item, file: filteredImages };
          }

          return item;
        });
        setState(updatedStepStates);
        return true;
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
        return false;
      }
    } else {
      const updatedImg = img.filter((item: FileType) => item !== imgSelect);
      setImg(updatedImg);
      const updatedStepStates = state.map((item: any) => {
        const existingFiles = item.file?.filter(
          (file: any) => file !== imgSelect
        );
        if (item.topic === topic) {
          return { ...item, file: existingFiles };
        }

        return item;
      });
      setState(updatedStepStates);
      return true;
    }
  };

  return (
    <tr>
      <td>{t(title)}</td>
      <td>
        <InspectionInputsDiv
          style={{
            outline:
              error && !checkBox?.some((x: any) => x.checked)
                ? '1px solid red'
                : 'none'
          }}
        >
          <RadioCheboxArea style={{ left: 'unset' }}>
            <RadioCheckbox>
              <input
                type="radio"
                name={`yes${topic}`}
                data-input="answer"
                id={`yes${topic}`}
                data-answer={AnswerEnum.Yes}
                checked={checkBox[0].checked}
                onChange={() => {
                  handleChange(AnswerEnum.Yes, topic);
                }}
              />
              <div className="transition"></div>
            </RadioCheckbox>
            <RadioCheck htmlFor={`yes${topic}`}>{t('Yes')}</RadioCheck>
          </RadioCheboxArea>
          <RadioCheboxArea style={{ left: 'unset' }}>
            <RadioCheckbox>
              <input
                type="radio"
                name={`no${topic}`}
                data-input="answer"
                id={`no${topic}`}
                data-answer={AnswerEnum.No}
                checked={checkBox[1].checked}
                onChange={() => {
                  handleChange(AnswerEnum.No, topic);
                }}
              />
              <div className="transition"></div>
            </RadioCheckbox>
            <RadioCheck htmlFor={`no${topic}`}>{t('No')}</RadioCheck>
          </RadioCheboxArea>
        </InspectionInputsDiv>
      </td>
      <td>
        <DefaultInput
          style={{ width: '193px' }}
          placeholder={`${t('Observation')} `}
          name="observation"
          data-input="observation"
          onChange={(event) => {
            handleChangeObservation(event.target.value);
          }}
          value={observationArea}
          error={
            listTopicsNeedNo.includes(topic)
              ? error && !observationArea && checkBox[1].checked
              : error && !observationArea && checkBox[0].checked
          }
        />
      </td>
      <td>
        <InputFile>
          <input
            type="file"
            name={`File${topic}`}
            multiple
            accept=".png, .jpg, .jpeg"
            id={`img${topic}`}
            onChange={(e) => {
              hanldeFileChange(e.target.files);
            }}
            style={{
              outline: listTopicsNeedNo.includes(topic)
                ? img.length <= 0 && error && checkBox[1].checked
                  ? '1px solid red'
                  : 'none'
                : img.length <= 0 && error && checkBox[0].checked
                  ? '1px solid red'
                  : 'none'
            }}
          />
        </InputFile>
        {img.length > 0 && (
          <FileList
            files={img}
            onDelete={(img: FileType) => {
              handleDeleteFile(img);
            }}
          />
        )}
      </td>
    </tr>
  );
}
