import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import Select from 'react-select';
import {
  DivInputViewImage,
  InputFile,
  InspectionInputsDiv
} from '../../../../Components/Forms/FormInspectionsStyle';
import { DefaultInput } from '../../../../Components/Inputs/InputsStyle';
import {
  DivSelectStyle,
  StyleInstrumentSelect
} from '../../../../Components/Selects/InstrumentSelect';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';
import { toastfyError, toastfySuccess } from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { ActionsRow } from '../../../../Screens/AdminScreen/AdminScreenStyle';
import {
  RadioCheboxArea,
  RadioCheck,
  RadioCheckbox
} from '../../../../Styles/Styles';
import { InspectionTextArea, RegisterArea } from '../../InspectionsStyle';
import {
  IdentifiesContainer,
  IdentifiesHeader,
  TextButtonAdd
} from './DamMassifStyles';
import { AnswerEnum } from '../../../../data/graphql/base-schema';
import ModalStep from '../../../../Components/V2/Molecules/ModalStep/ModalStep';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { API_URL, BUCKET_URL } from '../../../../utils/const';
import ModalConfirm from '../../../../Components/V2/Molecules/ModalConfirm/ModalConfirm';
import FileViewer from '../../../../Components/V2/Molecules/FileViewer/FileViewer';
import FileList from '../../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';

export function IdentifiedWaterEmergency({
  setStepTwoStates,
  stepTwoStates,
  inspectionId,
  mssStructure,
  errorNextStep,
  setUpdateStepTwo
}: {
  setStepTwoStates: Dispatch<SetStateAction<any[]>>;
  stepTwoStates: any[];
  inspectionId: string;
  mssStructure: any;
  errorNextStep: boolean;
  setUpdateStepTwo: any;
}) {
  const { t }: any = useTranslation();
  const [waterEmergency, setWaterEmergency] = useState<any[]>([]);
  const [waterEmergencyForm, setWaterEmergencyForm] = useState<any>({
    local: '',
    name: '',
    observation: '',
    visibleSolidTransport: '',
    file: [],
    fileSent: false
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [checkBox, setCheckBox] = useState([
    { checked: false },
    { checked: false }
  ]);
  const [img, setImg] = useState<FileType[]>([]);
  const [idDeleteWater, setIdDeleteWater] = useState<string>('');
  const [fileShowModal, setFileShowModal] = useState<FileType | null>(null);
  const [error, setError] = useState<boolean>(false);

  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: userData.token
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  const types = [
    { label: t('Yes'), id: 'yes', value: t('Yes') },
    { label: t('No'), id: 'no', value: t('No') }
  ];

  const handleChangeCheckbox = (value: AnswerEnum, topic: string) => {
    const updatedStepStates = stepTwoStates.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, answer: value };
      }

      return item;
    });
    setStepTwoStates(updatedStepStates);
    setCheckBox([
      { checked: value === AnswerEnum.Yes },
      { checked: value === AnswerEnum.No }
    ]);
  };

  const handleDelete = async (idWater: string) => {
    const deleteResponse = await client.mutate({
      mutation: gql`
        mutation DeleteIdentifiedWaterLeak(
          $inspectionId: String!
          $identifiedWaterLeakId: String!
        ) {
          deleteIdentifiedWaterLeak(
            inspectionId: $inspectionId
            identifiedWaterLeakId: $identifiedWaterLeakId
          )
        }
      `,
      variables: {
        inspectionId: inspectionId,
        identifiedWaterLeakId: idWater
      }
    });
    if (deleteResponse.data) {
      const filteredWaterEmergency = waterEmergency.filter(
        (e: any) => e.id !== idWater
      );
      setWaterEmergency(filteredWaterEmergency);
      setStepTwoStates((prev) =>
        prev?.map((item) =>
          item.topic === 'Water Emergency'
            ? { ...item, fields: filteredWaterEmergency }
            : item
        )
      );
      toastfySuccess(t('deletedSuccessfully'));
    }

    setIdDeleteWater('');
  };

  const handleDeleteWaterEmergency = async (Emergency: any) => {
    const filteredWaterEmergency = waterEmergency.filter(
      (item) => item !== Emergency
    );
    setWaterEmergency(filteredWaterEmergency);
    setStepTwoStates((prev) =>
      prev?.map((item) =>
        item.topic === 'Water Emergency'
          ? { ...item, fields: filteredWaterEmergency }
          : item
      )
    );
  };

  const handleEdit = (waterEmergency: any) => {
    setWaterEmergencyForm(waterEmergency);
    setShowModal(true);
    if (waterEmergency.id) {
      setImg(waterEmergency.file);
    } else {
      setImg(
        waterEmergency.file.map((file: any) => {
          return {
            id: file.id,
            name: file.image,
            url: URL.createObjectURL(file),
            file: file
          };
        })
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setWaterEmergencyForm({
      local: '',
      name: '',
      observation: '',
      visibleSolidTransport: '',
      file: [],
      fileSent: false
    });
    setImg([]);
  };

  async function postSituationAccess(formData: FormData) {
    const response = await fetch(
      `${API_URL}/inspection/${inspectionId}/images`,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `${userData.token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error('Erro');
    }
  }

  const addEmergency = async () => {
    if (
      waterEmergencyForm.local &&
      waterEmergencyForm.name &&
      waterEmergencyForm.observation &&
      waterEmergencyForm.visibleSolidTransport &&
      waterEmergencyForm.fileSent
    ) {
      if (waterEmergencyForm.id) {
        const editResponse = await client.mutate({
          mutation: gql`
            mutation UpdateIdentifiedWaterLeaks(
              $inspectionId: String!
              $newData: UpdateMssIdentifiedWaterLeaksInput!
            ) {
              updateIdentifiedWaterLeaks(
                inspectionId: $inspectionId
                newData: $newData
              )
            }
          `,
          variables: {
            inspectionId: inspectionId,
            newData: {
              id: waterEmergencyForm.id,
              local: waterEmergencyForm.local,
              name: waterEmergencyForm.name,
              observation: waterEmergencyForm.observation,
              visibleSolidTransport: waterEmergencyForm.visibleSolidTransport
            }
          }
        });
        const newImages = waterEmergencyForm.file.filter(
          (file: any) => !file.id
        );
        if (newImages.length > 0) {
          const formData = new FormData();
          newImages.forEach((image: any) => {
            formData.append(
              `mss=identifiedWaterLeaks=${waterEmergencyForm.id}`,
              image.file ? image.file : image
            );
          });
          await postSituationAccess(formData);
        }

        if (editResponse.data) {
          const updatedWaterEmergency = waterEmergency.map((water) => {
            if (water.id === waterEmergencyForm.id) {
              return {
                ...water,
                local: waterEmergencyForm.local,
                name: waterEmergencyForm.name,
                observation: waterEmergencyForm.observation,
                visibleSolidTransport: waterEmergencyForm.visibleSolidTransport,
                file: waterEmergencyForm.file
              };
            }

            return water;
          });
          setWaterEmergency(updatedWaterEmergency);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Water Emergency'
                ? { ...item, fields: updatedWaterEmergency }
                : item
            )
          );
          setWaterEmergencyForm({
            local: '',
            name: '',
            observation: '',
            visibleSolidTransport: '',
            file: [],
            fileSent: false
          });
          setImg([]);
          toastfySuccess(t('editedSuccessfully'));
          setError(false);
          setUpdateStepTwo((prev: boolean) => !prev);
          return true;
        } else {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
          setError(true);
          return false;
        }
      } else {
        if (mssStructure) {
          const createWaterResponse = await client.mutate({
            mutation: gql`
              mutation AddIdentifiedWaterLeak(
                $inspectionId: String!
                $newIdentifiedWaterLeak: MssIdentifiedWaterLeaksInput!
              ) {
                addIdentifiedWaterLeak(
                  inspectionId: $inspectionId
                  newIdentifiedWaterLeak: $newIdentifiedWaterLeak
                )
              }
            `,
            variables: {
              inspectionId: inspectionId,
              newIdentifiedWaterLeak: {
                local: waterEmergencyForm.local,
                observation: waterEmergencyForm.observation,
                name: waterEmergencyForm.name,
                visibleSolidTransport: waterEmergencyForm.visibleSolidTransport
              }
            }
          });
          if (createWaterResponse.data) {
            if (waterEmergencyForm.file.length > 0) {
              const formData = new FormData();
              waterEmergencyForm.file.forEach((image: any) => {
                formData.append(
                  `mss=identifiedWaterLeaks=${createWaterResponse.data.addIdentifiedWaterLeak}`,
                  image
                );
              });
              await postSituationAccess(formData);
            }

            const newWaterEmergency = {
              ...waterEmergencyForm,
              id: createWaterResponse.data.addIdentifiedWaterLeak,
              file: waterEmergencyForm.file,
              fileSent: waterEmergencyForm.fileSent
            };
            setWaterEmergency([...waterEmergency, newWaterEmergency]);
            setStepTwoStates((prev) =>
              prev?.map((item) =>
                item.topic === 'Water Emergency'
                  ? { ...item, fields: [...waterEmergency, newWaterEmergency] }
                  : item
              )
            );
            setWaterEmergencyForm({
              local: '',
              name: '',
              observation: '',
              visibleSolidTransport: '',
              file: [],
              fileSent: false
            });
            setImg([]);
            toastfySuccess(t('registeredSuccessfully'));
            setError(false);
            setUpdateStepTwo((prev: boolean) => !prev);
            return true;
          } else {
            toastfyError(t(ToastifyModel().toastifyMessage.error));
            setError(true);
            return false;
          }
        } else {
          setWaterEmergency([...waterEmergency, waterEmergencyForm]);
          setStepTwoStates((prev) =>
            prev?.map((item) =>
              item.topic === 'Water Emergency'
                ? { ...item, fields: [...waterEmergency, waterEmergencyForm] }
                : item
            )
          );
          setWaterEmergencyForm({
            local: '',
            name: '',
            observation: '',
            visibleSolidTransport: '',
            file: [],
            fileSent: false
          });
          setImg([]);
          setError(false);
          return true;
        }
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
      return false;
    }
  };

  const handleChange = (e: any) => {
    setWaterEmergencyForm((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleChangeImage = (files: any) => {
    const updatedImg = img.concat(
      Array.from(files).map((file: any) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file),
          file: file
        };
      })
    );
    setWaterEmergencyForm((prevState: any) => ({
      ...prevState,
      fileSent: true,
      file: [...prevState.file, ...files]
    }));
    setImg(updatedImg);
  };

  const handleDeleteImage = async (imgSelect: FileType) => {
    if (imgSelect.id) {
      const deleteResponse = await client.mutate({
        mutation: gql`
          mutation DeleteInspectionDataImage(
            $image: DeleteInspectionDataImageInput!
            $inspectionId: String!
          ) {
            deleteInspectionDataImage(
              image: $image
              inspectionId: $inspectionId
            )
          }
        `,
        variables: {
          inspectionId: inspectionId,
          image: {
            id: imgSelect.id,
            dataType: 'identifiedWaterLeaks'
          }
        }
      });
      if (deleteResponse.data) {
        const filteredImages = img.filter(
          (image: FileType) => image.id !== imgSelect.id
        );
        setImg(filteredImages);
        setWaterEmergencyForm((prevState: any) => ({
          ...prevState,
          file: filteredImages
        }));
        const updatedStepStates = stepTwoStates.map((item: any) => {
          if (item.topic === 'Water Emergency') {
            return {
              ...item,
              fields: item.fields.map((fieldItem: any) => {
                return {
                  ...fieldItem,
                  file: fieldItem.file.filter(
                    (file: any) => file.id !== imgSelect.id
                  )
                };
              })
            };
          }

          return item;
        });
        setStepTwoStates(updatedStepStates);
        return true;
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
        return false;
      }
    } else {
      const updatedImg = img.filter((item: FileType) => item !== imgSelect);
      setImg(updatedImg);
      const updateWaterEmergencyForm = waterEmergencyForm.file.filter(
        (file: any) => file !== imgSelect.file
      );
      setWaterEmergencyForm((prevState: any) => ({
        ...prevState,
        file: updateWaterEmergencyForm
      }));
      return true;
    }
  };

  useEffect(() => {
    if (stepTwoStates.length > 0) {
      stepTwoStates?.map((item: any) => {
        if (item.topic === 'Water Emergency') {
          setCheckBox([
            { checked: item.answer === AnswerEnum.Yes },
            { checked: item.answer === AnswerEnum.No }
          ]);
          setWaterEmergency(
            item.fields.map((water: any) => {
              let fileURLs: FileType[] = [];
              if (water.file?.[0]?.image) {
                fileURLs = water.file.map((file: any) => {
                  return {
                    id: file.id,
                    name: file.image,
                    url: `${BUCKET_URL}/${file.image}`
                  };
                });
              } else if (water.fileSent) {
                if (water.file.length > 0) {
                  fileURLs = water.file.map((file: any) => {
                    return file;
                  });
                }
              }

              return {
                ...water,
                file: fileURLs
              };
            }) || []
          );
        }
      });
    }
  }, [stepTwoStates]);

  return (
    <>
      <IdentifiesContainer>
        <IdentifiesHeader>
          <span id="water">
            {t('IdentifiedWaterEmergency')}
            <InspectionInputsDiv
              style={{
                justifyContent: 'flex-start',
                outline:
                  error && !checkBox.some((item) => item.checked)
                    ? '1px solid red'
                    : 'none'
              }}
            >
              <RadioCheboxArea style={{ left: 'unset' }}>
                <RadioCheckbox>
                  <input
                    type="radio"
                    name="IdentifiedWaterEmergency"
                    id="yes_IdentifiedWaterEmergency"
                    checked={checkBox[0].checked}
                    onChange={() =>
                      handleChangeCheckbox(AnswerEnum.Yes, 'Water Emergency')
                    }
                  />
                  <div className="transition"></div>
                </RadioCheckbox>
                <RadioCheck htmlFor="yes_IdentifiedWaterEmergency">
                  {t('Yes')}
                </RadioCheck>
              </RadioCheboxArea>
              <RadioCheboxArea style={{ left: 'unset' }}>
                <RadioCheckbox>
                  <input
                    type="radio"
                    name="IdentifiedWaterEmergency"
                    id="no_IdentifiedWaterEmergency"
                    checked={checkBox[1].checked}
                    onChange={() =>
                      handleChangeCheckbox(AnswerEnum.No, 'Water Emergency')
                    }
                  />
                  <div className="transition"></div>
                </RadioCheckbox>
                <RadioCheck htmlFor="no_IdentifiedWaterEmergency">
                  {t('No')}
                </RadioCheck>
              </RadioCheboxArea>
            </InspectionInputsDiv>
          </span>
          {checkBox[0].checked && (
            <TextButtonAdd
              style={{
                outline:
                  (error &&
                    checkBox[0].checked &&
                    waterEmergency.length <= 0) ||
                  errorNextStep
                    ? '2px solid red'
                    : 'none'
              }}
              onClick={() => {
                setShowModal(!showModal);
                setWaterEmergencyForm((prev: any) => ({ ...prev }));
              }}
            >
              {t('Add')}
            </TextButtonAdd>
          )}
        </IdentifiesHeader>
        {checkBox[0].checked && (
          <TableComponentStyle
            style={{
              outline:
                (error && checkBox[0].checked && waterEmergency.length <= 0) ||
                errorNextStep
                  ? '2px solid red'
                  : 'none'
            }}
          >
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Local')}</th>
                <th>{t('VisibleTransportOfSolids')}</th>
                <th>{t('Observation')}</th>
                <th>{t('Image')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {waterEmergency.map((water, index) => (
                <tr key={index}>
                  <td>{water.name}</td>
                  <td>{water.local}</td>
                  <td>{water.visibleSolidTransport}</td>
                  <td>
                    {water.observation && water.observation.length > 0
                      ? `${water.observation.slice(0, 9)}...`
                      : '-'}
                  </td>
                  <td>
                    {water.file?.map((file: any, index: number) => {
                      return (
                        <img
                          key={index}
                          src={file.url || URL.createObjectURL(file)}
                          alt="water"
                          style={{
                            width: '50px',
                            height: '50px',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setFileShowModal(file);
                          }}
                        />
                      );
                    }) || '-'}
                  </td>
                  <td>
                    <ActionsRow>
                      {water.id && (
                        <BiSolidPencil
                          onClick={() => {
                            handleEdit(water);
                          }}
                        />
                      )}
                      <MdDelete
                        onClick={() =>
                          water.id
                            ? setIdDeleteWater(water.id)
                            : handleDeleteWaterEmergency(water)
                        }
                      />
                    </ActionsRow>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponentStyle>
        )}
        {showModal && (
          <ModalStep
            width={30}
            onClose={handleCloseModal}
            title={
              waterEmergencyForm.id
                ? `${t('Edit')} ${t('EmergencyWater')}`
                : t('AddEmergency')
            }
            steps={[
              {
                name: '',
                component: (
                  <>
                    <RegisterArea>
                      <DefaultInput
                        style={{ width: '293px' }}
                        placeholder={`${t('Name')} `}
                        name="name"
                        onChange={(event: any) => {
                          handleChange(event);
                        }}
                        value={waterEmergencyForm.name}
                        error={error && !waterEmergencyForm.name}
                      />
                      <DefaultInput
                        style={{ width: '293px' }}
                        placeholder={`${t('Local')} `}
                        name="local"
                        onChange={(event: any) => {
                          handleChange(event);
                        }}
                        value={waterEmergencyForm.local}
                        error={error && !waterEmergencyForm.local}
                      />
                      <DivSelectStyle
                        style={{ width: '320px' }}
                        error={
                          error && !waterEmergencyForm.visibleSolidTransport
                        }
                      >
                        <Select
                          placeholder={t('VisibleTransportOfSolids')}
                          options={types}
                          styles={StyleInstrumentSelect}
                          name="visibleSolidTransport"
                          maxMenuHeight={200}
                          value={types.find(
                            (type) =>
                              type.value ===
                              waterEmergencyForm.visibleSolidTransport
                          )}
                          onChange={(event: any) => {
                            setWaterEmergencyForm((prev: any) => ({
                              ...prev,
                              visibleSolidTransport: event.value
                            }));
                          }}
                        />
                      </DivSelectStyle>
                      <InspectionTextArea
                        style={{
                          width: '293px',
                          height: '80px',
                          minHeight: '80px',
                          outline:
                            error && !waterEmergencyForm.observation
                              ? '1px solid red'
                              : 'none'
                        }}
                        placeholder={t('Observation')}
                        spellCheck={false}
                        name="observation"
                        value={waterEmergencyForm.observation}
                        onChange={(event: any) => {
                          handleChange(event);
                        }}
                      />
                      <DivInputViewImage>
                        <InputFile>
                          <input
                            type="file"
                            name="image"
                            id="File_water"
                            multiple
                            accept=".png, .jpg, .jpeg"
                            onChange={(event) => {
                              handleChangeImage(event.target.files);
                            }}
                            style={{
                              outline:
                                error && img.length <= 0
                                  ? '1px solid red'
                                  : 'none'
                            }}
                          />
                        </InputFile>
                        {img.length > 0 && (
                          <FileList
                            files={img}
                            onDelete={(img: FileType) => {
                              handleDeleteImage(img);
                            }}
                          />
                        )}
                      </DivInputViewImage>
                    </RegisterArea>
                  </>
                ),
                onNext: () => addEmergency()
              }
            ]}
          />
        )}
      </IdentifiesContainer>

      {idDeleteWater && (
        <ModalConfirm
          title={`${t('Delete')} ${t('EmergencyWater')}`}
          text={t('ConfirmDelete')}
          subtitle={t('ActionCannotBeUndone')}
          onConfirm={() => handleDelete(idDeleteWater)}
          onClose={() => setIdDeleteWater('')}
        />
      )}

      {fileShowModal && (
        <FileViewer
          files={[fileShowModal]}
          selectedFile={fileShowModal}
          setSelectedFile={setFileShowModal}
          onClose={() => {
            setFileShowModal(null);
          }}
        />
      )}
    </>
  );
}
