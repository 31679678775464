import { useTranslation } from 'react-i18next';

const Enums = () => {
  const { t: translate } = useTranslation();
  const OperationalStatus = [
    { value: 'Active', label: translate('Active') },
    { value: 'Inactive', label: translate('Inactive') }
  ];

  const TypeOfReading = [
    { value: 'Manual', label: translate('Manual') },
    { value: 'Automatic', label: translate('Automatic') }
  ];

  const TypeOfInstallation = [
    { value: 'Foundation', label: translate('Foundation') },
    { value: 'Massive', label: translate('Massive') },
    { value: 'Shoulder', label: translate('Shoulder') },
    { value: 'DrainingMat', label: translate('DrainingMat') },
    { value: 'VerticalFilter', label: translate('VerticalFilter') },
    { value: 'Other', label: translate('Other') }
  ];

  return { OperationalStatus, TypeOfReading, TypeOfInstallation };
};

export default Enums;
