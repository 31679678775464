import React, { useState } from 'react';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useDeleteInstrumen } from '../../../../data/hooks/use-delete-instrument';
import MenuRadio from '../../Molecules/MenuRadio/MenuRadio';
import Text from '../../Atoms/Text/Text';
import { RegisterInstrument } from '../../../Instruments/Register/RegisterInstrument';
import ExportData from '../../../../Controllers/Export/ExportData';
import InstrumentTemplate from '../../Templates/InstrumentTemplate/InstrumentTemplate';
import { capitalizeFirstLetter } from '../../../../utils/StringManipulattor';
import { useQuery } from '@apollo/client';
import {
  ListWaterLevelByStructureDocument,
  ListWaterLevelByStructureQuery,
  ListWaterLevelByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import ErrorsTreatments from '../../../../utils/errorTreatment';

const WaterLevelListPage = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { structureId, instrumentType } = useParams();
  const { deleteInstruments } = useDeleteInstrumen();

  const [idDelete, setIdDelete] = useState<string>('');
  const [showModalCreate, setShowModalCreate] = useState<boolean>(false);

  const { data: listAllWaterLevel, loading } = useQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument, {
    variables: {
      structureInfo: {
        structureId: structureId!,
        associatedStructureId: null
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const instruments = [
    {
      label: translate('Piezometer'),
      value: 'piezometer',
      onClick: (option: { value: string | number; label: string }) => {
        navigate(`/${structureId}/monitoring/instrument/${option.value}`);
      }
    },
    {
      label: translate('INA'),
      value: 'ina',
      onClick: (option: { value: string | number; label: string }) => {
        navigate(`/${structureId}/monitoring/instrument/${option.value}`);
      }
    },
    {
      label: translate('Pluviometer'),
      value: 'pluviometer',
      onClick: (option: { value: string | number; label: string }) => {
        navigate(`/${structureId}/monitoring/instrument/${option.value}`);
      }
    },
    {
      label: translate('WaterLevel'),
      value: 'waterlevel',
      onClick: (option: { value: string | number; label: string }) => {
        navigate(`/${structureId}/monitoring/instrument/${option.value}`);
      }
    },
    {
      label: translate('SurfaceMarker'),
      value: 'surfacemarker',
      onClick: (option: { value: string | number; label: string }) => {
        navigate(`/${structureId}/monitoring/instrument/${option.value}`);
      }
    }
  ];

  const actionsTable: AvailableContextActionsProps<any>[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (instrument) => {
        const currentPath = window.location.pathname;
        navigate(`${currentPath}/edit/${instrument.id}`);
      },
      canShow: 'canEdit'
    },
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (instrument) => {
        setIdDelete(instrument.id);
      },
      canShow: 'canDelete'
    }
  ];

  const handleDeleteInstrument = async (instrumentId: string) => {
    const response = await deleteInstruments({
      variables: {
        structureId: structureId!,
        instrumentId: instrumentId
      }
    });
    toastifyLoading('Deletando Instrumento...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Instrumento Deletada Com Sucesso');
      toastfyDimiss('toastfySuccess');
      setIdDelete('');
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message ||
          translate(ToastifyModel().toastifyMessage.error)
      );
      setIdDelete('');
    }
  };

  const instrumentfilteredData = listAllWaterLevel?.listWaterLevelByStructure;

  const rowsInstrumentData = instrumentfilteredData?.map((instrument) => {
    return {
      ...instrument,
      operationalStatus: translate(instrument.operationalStatus),
      readingType: translate(instrument.readingType),
      installationDate: instrument.installationDate
        ? new Date(instrument.installationDate).toLocaleDateString()
        : translate('NoDataAvailable'),
      deactivationDate: instrument.deactivationDate
        ? new Date(instrument.deactivationDate).toLocaleDateString()
        : translate('NoDataAvailable'),
      activationDate: instrument.activationDate
        ? new Date(instrument.activationDate).toLocaleDateString()
        : translate('NoDataAvailable'),
      installLocation: instrument.installLocation
        ? translate(instrument.installLocation)
        : translate('NoDataAvailable')
    };
  });

  const WaterLevelColumns = [
    {
      key: 'name',
      label: translate('name')
    },
    {
      key: 'operationalStatus',
      label: translate('operationalStatus')
    },
    {
      key: 'coordinateE',
      label: translate('coordinateE')
    },
    {
      key: 'coordinateN',
      label: translate('coordinateN')
    },
    {
      key: 'installationDate',
      label: translate('installationDate')
    },
    {
      key: 'activationDate',
      label: translate('activationDate')
    },
    {
      key: 'installLocation',
      label: translate('installLocation')
    },
    {
      key: 'readingType',
      label: translate('readingType')
    },
    {
      key: 'deactivationDate',
      label: translate('deactivationDate')
    }
  ];

  return (
    <>
      <InstrumentTemplate
        loading={loading}
        title={
          <Text type="h1" size={'large'}>
            {translate(capitalizeFirstLetter(instrumentType!))}
          </Text>
        }
        button={
          <Button
            variant={'primary'}
            text={translate('RegisterInstrument')}
            onClick={() => setShowModalCreate(true)}
          />
        }
        menu={
          <MenuRadio
            title={translate('Instruments')}
            options={instruments || []}
            preSelected={instrumentType}
          />
        }
        table={
          <TableComponent
            columns={WaterLevelColumns}
            tableData={rowsInstrumentData || []}
            actions={actionsTable}
            exportTable={(fields: any) => {
              const exportData = rowsInstrumentData?.map((item: any) => {
                return fields.reduce((result: any, field: any) => {
                  if (Object.prototype.hasOwnProperty.call(item, field)) {
                    result[field] = item[field];
                  }

                  return result;
                }, {});
              });

              ExportData.exportInstrumentList(
                exportData,
                instrumentType!,
                fields
              );
            }}
          />
        }
      />
      {idDelete && (
        <ModalConfirm
          title={translate('Deletar Instrumento')}
          text={translate('ConfirmDelete')}
          subtitle={translate('ActionCannotBeUndone')}
          onClose={() => setIdDelete('')}
          onConfirm={() => {
            handleDeleteInstrument(idDelete);
          }}
        />
      )}
      {showModalCreate && (
        <RegisterInstrument
          setShowModal={setShowModalCreate}
          showModal={showModalCreate}
          setDataChanged={setShowModalCreate}
        />
      )}
    </>
  );
};

export default WaterLevelListPage;
