import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const Container = styled.div`
  background-color: ${Color.White};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-x: hidden;
  padding: 0px 30px 0px 30px;
  box-sizing: border-box;
  overflow: auto;
`;

export const DivHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const DivTitle = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const DivContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DivButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DivHolderButtonsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;
