import styled from 'styled-components';

export const GraphWindowInstrument = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (max-height: 800px) {
    height: 80%;
  }
`;

export const DatePickerContainerStyle = styled.div`
  display: flex;
  align-items: center;
`;
