import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  gql,
  useMutation
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { UserData } from '../../../@Types/types';
import { toastfyError, toastfySuccess } from '../../../Components/Toastify';
import ModalStep from '../../../Components/V2/Molecules/ModalStep/ModalStep';
import { ProjectContext } from '../../../Context/ContextAPI';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AnswerEnum,
  ConservationStateNoteEnum,
  ConservationStateTitleEnum,
  MssGenericDataInput,
  MssGenericDataTitleEnum,
  SaveConservationStateInput,
  SaveGenericDataInput,
  TitleEnum
} from '../../../data/graphql/base-schema';
import {
  UpdateGeneralCommentsDocument,
  UpdateGeneralCommentsMutation,
  UpdateGeneralCommentsMutationVariables
} from '../../../data/graphql/generated/updateGeneralComments';
import { FindAllInspectionsByStructureDocument } from '../../../data/graphql/query/generated/findAllInspectionsByStructure.query';
import { useGenerateInspectionPdf } from '../../../data/hooks/use-generate-inspection-pdf';
import { useSaveConservationState } from '../../../data/hooks/use-save-conservation-state.mutation';
import { useGeneralComments } from '../../../data/hooks/use-save-general-comments';
import { useUpdateConservationState } from '../../../data/hooks/use-update-conservation-state';
import { API_URL } from '../../../utils/const';
import { FifthStepInspection } from './FifthStepInspection';
import { FirstStepInspection } from './FirstStepInspection';
import { FourthStepInspection } from './FourthStepInspection';
import { SecondStepInspection } from './SecondStepInspection';
import { SeventhStepInspection } from './SeventhStepInspection';
import { SixthStepInspection } from './SixthStepInspection';
import { ThirdStepInspection } from './ThirdStepInspection';

interface InspectionType {
  conservationStateCalc: number;
  date: string;
  generalComments: string;
  id: string;
  participants: InspectionParticipantsType[];
  responsible: string;
  structureId: string;
  systemId: number;
  systemPdf: string;
  userPdf: string;
}

interface InspectionParticipantsType {
  company: string;
  id: string;
  name: string;
  position: string;
  signature: string;
  status: boolean;
}

export function StepsRegisterInspections({
  showModal,
  setShowModal,
  selectedInspection,
  inspectionId,
  editing,
  setShowStepsInspectionsModal
}: {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  selectedInspection: InspectionType | undefined;
  inspectionId: string;
  editing: boolean;
  setShowStepsInspectionsModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { saveConservationState } = useSaveConservationState();
  const { updateConservationState } = useUpdateConservationState();
  const { saveGeneralComments } = useGeneralComments();
  const [updateGeneralComments] = useMutation<
    UpdateGeneralCommentsMutation,
    UpdateGeneralCommentsMutationVariables
  >(UpdateGeneralCommentsDocument);
  const { generateInspectionPdf } = useGenerateInspectionPdf();
  const { t }: any = useTranslation();
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const [stepOneStates, setStepOneStates] = useState<SaveGenericDataInput[]>([
    {
      topic: 'Conservação Geral',
      title: TitleEnum.AccessSituation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Taludes',
      title: TitleEnum.AccessSituation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Revestimento do Piso',
      title: TitleEnum.AccessSituation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Dispositivos de drenagem',
      title: TitleEnum.AccessSituation,
      observation: '',
      answer: AnswerEnum.NA
    }
  ]);
  const [stepTwoStates, setStepTwoStates] = useState<MssGenericDataInput[]>([
    {
      title: 'Cracks',
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Cracks',
      fields: []
    },
    {
      title: 'Repressions',
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Repressions',
      fields: []
    },
    {
      title: 'Water Emergency',
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Water Emergency',
      fields: []
    },
    {
      title: MssGenericDataTitleEnum.SurfaceErosions,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'SurfaceErosions'
    },
    {
      title: MssGenericDataTitleEnum.SurfaceDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Estado de limpeza'
    },
    {
      title: MssGenericDataTitleEnum.SurfaceDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Condições estruturais'
    },
    {
      title: MssGenericDataTitleEnum.Coating,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Coating'
    },
    {
      title: MssGenericDataTitleEnum.PresenceOfTreesAnimalsInsects,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Presença de árvores ou arbustos'
    },
    {
      title: MssGenericDataTitleEnum.PresenceOfTreesAnimalsInsects,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Vegetação de cor mais destacadas'
    },
    {
      title: MssGenericDataTitleEnum.PresenceOfTreesAnimalsInsects,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Formigueiros / Cupins / Roedores'
    },
    {
      title: MssGenericDataTitleEnum.PresenceOfTreesAnimalsInsects,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Animais (verificar pegadas / fezes)'
    },
    {
      title: MssGenericDataTitleEnum.PresenceOfTreesAnimalsInsects,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Presença de tocas ou buracos'
    },
    {
      title: MssGenericDataTitleEnum.InternalDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Assoreamento da saída do dreno/colóide'
    },
    {
      title: MssGenericDataTitleEnum.InternalDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Carreamento visível de sólidos'
    },
    {
      title: MssGenericDataTitleEnum.InternalDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Alteração significativa da vazão'
    },
    {
      title: MssGenericDataTitleEnum.InternalDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Saturação ao redor do dreno'
    },
    {
      title: MssGenericDataTitleEnum.InternalDrainage,
      answer: AnswerEnum.NA,
      observation: '',
      topic: 'Medidor de vazão operante'
    }
  ]);
  const [stepThreeStates, setStepThreeStates] = useState<
    SaveGenericDataInput[]
  >([
    {
      topic: 'Erosões ou processos de instabilização',
      title: TitleEnum.Reservoir,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Borda Livre Operacional',
      title: TitleEnum.Reservoir,
      observation: '',
      answer: AnswerEnum.NA
    }
  ]);
  const [stepFourStates, setStepFourStates] = useState<SaveGenericDataInput[]>([
    {
      topic: 'Obstrução da seção de emboque / stop logs?',
      title: TitleEnum.Extravasator,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Há danos na(s) estrutura(s)',
      title: TitleEnum.Extravasator,
      observation: '',
      answer: AnswerEnum.NA
    }
  ]);
  const [stepFiveStates, setStepFiveStates] = useState<SaveGenericDataInput[]>([
    {
      topic: 'O acesso aos piezômetros está limpo e em boas condições?',
      title: TitleEnum.Instrumentation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Os piezômetros estão protegidos e trancados?',
      title: TitleEnum.Instrumentation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Os piezômetros estão em bom estado de conservação?',
      title: TitleEnum.Instrumentation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'As réguas de nível estão bom estado de conservação e leitura?',
      title: TitleEnum.Instrumentation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Os instrumentos estão devidamente identificados?',
      title: TitleEnum.Instrumentation,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Outros (identificar)',
      title: TitleEnum.Instrumentation,
      observation: '',
      answer: AnswerEnum.NA
    }
  ]);
  const [stepSixStates, setStepSixStates] = useState<SaveGenericDataInput[]>([
    {
      topic: 'Alguma irregularidade no bombeamento',
      title: TitleEnum.WaterWastePumpingPiping,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Alguma irregularidade nas tubulações',
      title: TitleEnum.WaterWastePumpingPiping,
      observation: '',
      answer: AnswerEnum.NA
    },
    {
      topic: 'Outros (identificar)',
      title: TitleEnum.WaterWastePumpingPiping,
      observation: '',
      answer: AnswerEnum.NA
    }
  ]);
  const [stepSevenStates, setStepSevenStates] = useState<
    SaveConservationStateInput[]
  >([
    {
      note: ConservationStateNoteEnum.Zero,
      title: ConservationStateTitleEnum.ReliabilityExtravasationStructures,
      topic:
        'Estruturas civis bem mantidas e em operação normal /barragem sem necessidade de estruturas extravasoras'
    },
    {
      note: ConservationStateNoteEnum.Zero,
      title: ConservationStateTitleEnum.Percolation,
      topic: 'Percolação totalmente controlada pelo sistema de drenagem'
    },
    {
      note: ConservationStateNoteEnum.Zero,
      title: ConservationStateTitleEnum.Deformations,
      topic:
        'Não existem deformações e recalques com potencial de comprometimento da segurança da estrutura'
    },
    {
      note: ConservationStateNoteEnum.Zero,
      title: ConservationStateTitleEnum.SlopeAndParametersDeterioration,
      topic: 'Não existe deterioração de taludes e paramentos'
    },
    {
      note: ConservationStateNoteEnum.Zero,
      title: ConservationStateTitleEnum.SurfaceDrainage,
      topic: 'Drenagem superficial existente e operante'
    }
  ]);
  const [generalComments, setGeneralComments] = useState<string>('');
  const [isSelectedSurfaceDrainage, setIsSelectedSurfaceDrainage] =
    useState<boolean>(false);
  const [isSelectedInternalDrainage, setIsSelectedInternalDrainage] =
    useState<boolean>(false);
  const [inspection, setInspection] = useState<any>();
  const [error, setError] = useState(false);
  const [updateStepTwo, setUpdateStepTwo] = useState(false);
  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: userData.token
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  const handleGetInspection = async () => {
    const response = await client.query({
      query: gql`
        query FindDataByInspection($inspectionId: String!) {
          findDataByInspection(inspectionId: $inspectionId) {
            accessSituation {
              images {
                id
                image
                status
                latitude
                longitude
                date
              }
              observation
              answer
              topic
              title
              id
            }
            reservoir {
              images {
                id
                image
                status
                latitude
                longitude
                date
              }
              observation
              answer
              topic
              title
              id
            }
            extravasator {
              images {
                id
                image
                status
                latitude
                longitude
                date
              }
              observation
              answer
              title
              id
              topic
            }
            instrumentation {
              images {
                id
                image
                status
                latitude
                longitude
                date
              }
              observation
              answer
              topic
              title
              id
            }
            waterWastePumpingPiping {
              images {
                id
                image
                status
                latitude
                longitude
                date
              }
              observation
              answer
              topic
              title
              id
            }
            conservationState {
              id
              title
              topic
              note
            }
            massiveStructureShoulder {
              identifiedWaterLeaks {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                observation
                visibleSolidTransport
                local
                name
                id
              }
              identifiedDeformations {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                observation
                local
                id
                identifiedAnomaly
              }
              identifiedCracks {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                id
                name
                length
                opening
                longitudinalTransverse
                observation
              }
              surfaceDrainage {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                topic
                observation
                answer
                title
                id
              }
              presenceOfTreesAnimalsInsects {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                topic
                observation
                answer
                title
                id
              }
              internalDrainage {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                topic
                observation
                answer
                title
                id
              }
              surfaceErosions {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                observation
                answer
                title
                id
              }
              coating {
                images {
                  id
                  image
                  status
                  latitude
                  longitude
                  date
                }
                observation
                answer
                title
                id
              }
            }
          }
        }
      `,
      variables: {
        inspectionId: inspectionId
      }
    });
    if (response.data) {
      setInspection(response.data);
    }
  };

  useEffect(() => {
    if (inspectionId) {
      handleGetInspection();
    }
  }, [inspectionId, updateStepTwo]);

  useEffect(() => {
    if (inspection?.findDataByInspection) {
      if (inspection?.findDataByInspection?.accessSituation?.length > 0) {
        const stepOne = inspection?.findDataByInspection.accessSituation.map(
          (x: any) => {
            return {
              topic: x.topic,
              title: x.title,
              observation: x.observation,
              answer: x.answer,
              id: x.id,
              fileSent: x.images.length > 0 ? true : false,
              file: x.images
            };
          }
        );
        setStepOneStates(stepOne);
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .identifiedCracks?.length > 0
      ) {
        const identifiedCracks = {
          topic: 'Cracks',
          answer: AnswerEnum.Yes,
          title: 'Cracks',
          fields:
            inspection?.findDataByInspection.massiveStructureShoulder.identifiedCracks.map(
              (x: any) => {
                return {
                  id: x.id,
                  name: x.name,
                  length: x.length,
                  opening: x.opening,
                  longitudinalTransverse: x.longitudinalTransverse,
                  observation: x.observation,
                  fileSent: x.images.length > 0 ? true : false,
                  file: x.images
                };
              }
            )
        };
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Cracks' ? { ...identifiedCracks } : item
          )
        );
      } else {
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Cracks' ? { ...item, answer: AnswerEnum.No } : item
          )
        );
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .identifiedDeformations?.length > 0
      ) {
        const identifiedDeformations = {
          topic: 'Repressions',
          answer: AnswerEnum.Yes,
          title: 'Repressions',
          fields:
            inspection?.findDataByInspection.massiveStructureShoulder.identifiedDeformations.map(
              (x: any) => {
                return {
                  id: x.id,
                  identifiedAnomaly: x.identifiedAnomaly,
                  local: x.local,
                  observation: x.observation,
                  fileSent: x.images.length > 0 ? true : false,
                  file: x.images
                };
              }
            )
        };
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Repressions' ? { ...identifiedDeformations } : item
          )
        );
      } else {
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Repressions'
              ? { ...item, answer: AnswerEnum.No }
              : item
          )
        );
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .identifiedWaterLeaks?.length > 0
      ) {
        const identifiedWaterLeaks = {
          topic: 'Water Emergency',
          answer: AnswerEnum.Yes,
          title: 'Water Emergency',
          fields:
            inspection?.findDataByInspection.massiveStructureShoulder.identifiedWaterLeaks.map(
              (x: any) => {
                return {
                  id: x.id,
                  name: x.name,
                  local: x.local,
                  visibleSolidTransport: x.visibleSolidTransport,
                  observation: x.observation,
                  fileSent: x.images.length > 0 ? true : false,
                  file: x.images
                };
              }
            )
        };
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Water Emergency'
              ? { ...identifiedWaterLeaks }
              : item
          )
        );
      } else {
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Water Emergency'
              ? { ...item, answer: AnswerEnum.No }
              : item
          )
        );
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .surfaceErosions
      ) {
        const surfaceErosionsResult =
          inspection?.findDataByInspection.massiveStructureShoulder
            .surfaceErosions;
        const surfaceErosions = {
          id: surfaceErosionsResult.id,
          title: surfaceErosionsResult.title,
          topic: surfaceErosionsResult.title,
          observation: surfaceErosionsResult.observation,
          answer: surfaceErosionsResult.answer,
          fileSent: surfaceErosionsResult.images.length > 0 ? true : false,
          file: surfaceErosionsResult.images
        };
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'SurfaceErosions' ? { ...surfaceErosions } : item
          )
        );
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .surfaceDrainage?.length > 0
      ) {
        const surfaceDrainage =
          inspection?.findDataByInspection.massiveStructureShoulder.surfaceDrainage.map(
            (x: any) => ({
              title: x.title,
              topic: x.topic,
              observation: x.observation,
              answer: x.answer,
              id: x.id,
              fileSent: x.images.length > 0 ? true : false,
              file: x.images
            })
          );
        surfaceDrainage.forEach((x: any) => {
          if (x.topic === 'Estado de limpeza') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Estado de limpeza' ? { ...x } : item
              )
            );
          } else if (x.topic === 'Condições estruturais') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Condições estruturais' ? { ...x } : item
              )
            );
          }
        });
        if (surfaceDrainage.every((x: any) => x.answer === AnswerEnum.NA)) {
          setIsSelectedSurfaceDrainage(true);
        }
      }

      if (inspection?.findDataByInspection.massiveStructureShoulder.coating) {
        const coatingResult =
          inspection?.findDataByInspection.massiveStructureShoulder.coating;
        const coating = {
          topic: coatingResult.title,
          title: coatingResult.title,
          observation: coatingResult.observation,
          answer: coatingResult.answer,
          id: coatingResult.id,
          fileSent: coatingResult.images.length > 0 ? true : false,
          file: coatingResult.images
        };
        setStepTwoStates((prev: any) =>
          prev?.map((item: any) =>
            item.topic === 'Coating' ? { ...coating } : item
          )
        );
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .presenceOfTreesAnimalsInsects?.length > 0
      ) {
        const presenceOfTreesAnimalsInsects =
          inspection?.findDataByInspection.massiveStructureShoulder.presenceOfTreesAnimalsInsects.map(
            (x: any) => {
              return {
                title: x.title,
                topic: x.topic,
                observation: x.observation,
                answer: x.answer,
                id: x.id,
                fileSent: x.images.length > 0 ? true : false,
                file: x.images
              };
            }
          );
        presenceOfTreesAnimalsInsects.forEach((x: any) => {
          if (x.topic === 'Presença de árvores ou arbustos') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Presença de árvores ou arbustos'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Vegetação de cor mais destacadas') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Vegetação de cor mais destacadas'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Formigueiros / Cupins / Roedores') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Formigueiros / Cupins / Roedores'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Animais (verificar pegadas / fezes)') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Animais (verificar pegadas / fezes)'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Presença de tocas ou buracos') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Presença de tocas ou buracos' ? { ...x } : item
              )
            );
          }
        });
      }

      if (
        inspection?.findDataByInspection.massiveStructureShoulder
          .internalDrainage?.length > 0
      ) {
        const internalDrainage =
          inspection?.findDataByInspection.massiveStructureShoulder.internalDrainage.map(
            (x: any) => {
              return {
                title: x.title,
                topic: x.topic,
                observation: x.observation,
                answer: x.answer,
                id: x.id,
                fileSent: x.images.length > 0 ? true : false,
                file: x.images
              };
            }
          );
        internalDrainage.forEach((x: any) => {
          if (x.topic === 'Assoreamento da saída do dreno/colóide') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Assoreamento da saída do dreno/colóide'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Carreamento visível de sólidos') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Carreamento visível de sólidos'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Alteração significativa da vazão') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Alteração significativa da vazão'
                  ? { ...x }
                  : item
              )
            );
          } else if (x.topic === 'Saturação ao redor do dreno') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Saturação ao redor do dreno' ? { ...x } : item
              )
            );
          } else if (x.topic === 'Medidor de vazão operante') {
            setStepTwoStates((prev: any) =>
              prev?.map((item: any) =>
                item.topic === 'Medidor de vazão operante' ? { ...x } : item
              )
            );
          }
        });
        if (internalDrainage.every((x: any) => x.answer === AnswerEnum.NA)) {
          setIsSelectedInternalDrainage(true);
        }
      }

      if (inspection?.findDataByInspection.reservoir?.length > 0) {
        const stepThree = inspection?.findDataByInspection.reservoir.map(
          (x: any) => {
            return {
              topic: x.topic,
              title: x.title,
              observation: x.observation,
              answer: x.answer,
              id: x.id,
              fileSent: x.images.length > 0 ? true : false,
              file: x.images
            };
          }
        );
        setStepThreeStates(stepThree);
      }

      if (inspection?.findDataByInspection.extravasator?.length > 0) {
        const stepFour = inspection?.findDataByInspection.extravasator.map(
          (x: any) => {
            return {
              topic: x.topic,
              title: x.title,
              observation: x.observation,
              answer: x.answer,
              id: x.id,
              fileSent: x.images.length > 0 ? true : false,
              file: x.images
            };
          }
        );
        setStepFourStates(stepFour);
      }

      if (inspection?.findDataByInspection.instrumentation?.length > 0) {
        const stepFive = inspection?.findDataByInspection.instrumentation.map(
          (x: any) => {
            return {
              topic: x.topic,
              title: x.title,
              observation: x.observation,
              answer: x.answer,
              id: x.id,
              fileSent: x.images.length > 0 ? true : false,
              file: x.images
            };
          }
        );
        setStepFiveStates(stepFive);
      }

      if (
        inspection?.findDataByInspection.waterWastePumpingPiping?.length > 0
      ) {
        const stepSix =
          inspection?.findDataByInspection.waterWastePumpingPiping.map(
            (x: any) => {
              return {
                topic: x.topic,
                title: x.title,
                observation: x.observation,
                answer: x.answer,
                id: x.id,
                fileSent: x.images.length > 0 ? true : false,
                file: x.images
              };
            }
          );
        setStepSixStates(stepSix);
      }

      if (inspection?.findDataByInspection.conservationState?.length > 0) {
        const stepSeven =
          inspection?.findDataByInspection.conservationState.map((x: any) => {
            return {
              topic: x.topic,
              title: x.title,
              note: x.note,
              id: x.id
            };
          });
        setStepSevenStates(stepSeven);
      }
    }

    if (selectedInspection?.generalComments) {
      setGeneralComments(selectedInspection?.generalComments);
    }
  }, [inspection]);

  async function postSituationAccess(formData: FormData) {
    const response = await fetch(
      `${API_URL}/inspection/${inspectionId}/images`,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `${userData.token}`
        }
      }
    );

    if (response.status !== 200) {
      throw new Error('Erro');
    }
  }

  const handleStep = async (stepStates: any[]) => {
    if (handleValidation(stepStates)) {
      const data = stepStates.map((x) => {
        const { fileSent, file, ...rest } = x;
        return rest;
      });
      if (data.every((x) => x.id)) {
        const editResponse = await client.mutate({
          mutation: gql`
            mutation UpdateGenericData($newData: [UpdateGenericDataInput!]!) {
              updateGenericData(newData: $newData)
            }
          `,
          variables: {
            newData: data
          }
        });
        const newImages = stepStates.map((state) => {
          return state.file?.filter((file: any) => !file.id);
        });

        if (newImages.some((images) => images && images.length > 0)) {
          const formData = new FormData();
          stepStates.forEach((state) => {
            const updateTemp = state.file?.filter((file: any) => !file.id);
            if (updateTemp.length > 0) {
              state.file
                .filter((fileItem: any) => !fileItem.id)
                .forEach((fileItem: any) => {
                  formData.append(`genericData=${state.id}`, fileItem);
                });
            }
          });
          await postSituationAccess(formData);
          handleGetInspection();
        }

        if (editResponse.data) {
          if (newImages.some((images) => images && images.length > 0)) {
            toastfySuccess(t('editedSuccessfully'));
            return true;
          } else {
            toastfySuccess(
              editResponse.data.updateGenericData || t('editedSuccessfully')
            );
            return true;
          }
        } else if (editResponse.errors) {
          toastfyError(
            editResponse.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
          return false;
        }

        handleGetInspection();
        return true;
      } else {
        const createResponse = await client.mutate({
          mutation: gql`
            mutation SaveGenericData(
              $inspectionId: String!
              $genericData: [SaveGenericDataInput!]!
            ) {
              saveGenericData(
                inspectionId: $inspectionId
                genericData: $genericData
              ) {
                topic
                title
                observation
                id
                answer
              }
            }
          `,
          variables: {
            inspectionId: inspectionId,
            genericData: data
          }
        });
        if (createResponse.data) {
          const formData = new FormData();
          let count = 0;
          createResponse?.data?.saveGenericData?.forEach((x: any) => {
            stepStates.forEach((y) => {
              if (y.topic === x.topic) {
                y.id = x.id;
                if (y.fileSent && Array.isArray(y.file)) {
                  y.file.forEach((fileItem: any) => {
                    formData.append(`genericData=${y.id}`, fileItem);
                    count++;
                  });
                }
              }
            });
          });
          if (count > 0) await postSituationAccess(formData);
          toastfySuccess(t('registeredSuccessfully'));
          handleGetInspection();
          return true;
        } else if (createResponse.errors) {
          toastfyError(
            createResponse.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
          return false;
        }
      }
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return false;
    }

    return false;
  };

  const handleValidation = (stepStates: any[]) => {
    const listTopicsNeedNo = [
      'Borda Livre Operacional',
      'O acesso aos piezômetros está limpo e em boas condições?',
      'Os piezômetros estão protegidos e trancados?',
      'Os piezômetros estão em bom estado de conservação?',
      'As réguas de nível estão bom estado de conservação e leitura?',
      'Os instrumentos estão devidamente identificados?',
      'Medidor de vazão operante'
    ];
    const resNA = stepStates.filter(
      (x) =>
        x.answer === AnswerEnum.NA || x.answer === null || !x.title || !x.topic
    );
    if (resNA.length > 0) {
      setError(true);
      return false;
    }

    const resYes = stepStates.filter(
      (x) => x.answer === AnswerEnum.Yes && !listTopicsNeedNo.includes(x.topic)
    );
    const resNo = stepStates.filter(
      (x) => x.answer === AnswerEnum.No && listTopicsNeedNo.includes(x.topic)
    );
    if (resYes.length > 0 && resYes) {
      if (
        resYes.some(
          (x) =>
            !x.fileSent ||
            x.fileSent === false ||
            !x.observation ||
            x.observation === '' ||
            x.file.length === 0
        )
      ) {
        setError(true);
        return false;
      }
    }

    if (resNo.length > 0) {
      if (
        resNo.some(
          (x) =>
            !x.fileSent ||
            x.fileSent === false ||
            !x.observation ||
            x.observation === '' ||
            x.file.length === 0
        )
      ) {
        setError(true);
        return false;
      }
    }

    const resNotGood = stepStates.filter(
      (x) =>
        x.answer === AnswerEnum.Regular || x.answer === AnswerEnum.Deficient
    );
    if (resNotGood.length > 0) {
      if (
        resNotGood.some(
          (x) =>
            !x.fileSent ||
            x.fileSent === false ||
            !x.observation ||
            x.observation === '' ||
            x.file.length === 0
        )
      ) {
        setError(true);
        return false;
      }
    }

    setError(false);
    return true;
  };

  function validateStep2() {
    const topicsValidationOne = ['Cracks', 'Repressions', 'Water Emergency'];
    const topicValidationTwo = [
      'SurfaceErosions',
      'Presença de árvores ou arbustos',
      'Vegetação de cor mais destacadas',
      'Formigueiros / Cupins / Roedores',
      'Animais (verificar pegadas / fezes)',
      'Presença de tocas ou buracos'
    ];
    const topicValidationThree = [
      'Assoreamento da saída do dreno/colóide',
      'Carreamento visível de sólidos',
      'Alteração significativa da vazão',
      'Saturação ao redor do dreno',
      'Medidor de vazão operante'
    ];
    const topicValidationFour = ['Estado de limpeza', 'Condições estruturais'];
    const stepTwoStatesValidationOne = stepTwoStates.filter((x: any) =>
      topicsValidationOne.includes(x.topic)
    );
    const stepTwoStatesValidatioTwo = stepTwoStates.filter((x: any) =>
      topicValidationTwo.includes(x.topic)
    );
    const stepTwoStatesValidatioThree = stepTwoStates.filter((x: any) =>
      topicValidationThree.includes(x.topic)
    );
    const stepTwoStatesValidatioFour = stepTwoStates.filter((x: any) =>
      topicValidationFour.includes(x.topic)
    );
    const stepTwoStatesValidationFive = stepTwoStates.filter(
      (x: any) => x.topic === 'Coating'
    );
    if (
      stepTwoStatesValidationOne.some((x: any) => x.answer === AnswerEnum.NA) ||
      stepTwoStatesValidatioTwo.some((x: any) => x.answer === AnswerEnum.NA) ||
      (!isSelectedInternalDrainage &&
        stepTwoStatesValidatioThree.some(
          (x: any) => x.answer === AnswerEnum.NA
        )) ||
      (!isSelectedSurfaceDrainage &&
        stepTwoStatesValidatioFour.some(
          (x: any) => x.answer === AnswerEnum.NA
        )) ||
      stepTwoStatesValidationFive.some((x: any) => x.answer === AnswerEnum.NA)
    ) {
      setError(true);
      return false;
    }

    if (
      stepTwoStatesValidationOne.some((x: any) => x.answer === AnswerEnum.Yes)
    ) {
      const res = stepTwoStatesValidationOne.filter(
        (x: any) => x.answer === AnswerEnum.Yes
      );
      if (res.length > 0) {
        if (res.some((x: any) => x.fields.length <= 0)) {
          setError(true);
          return false;
        }
      }
    }

    if (!handleValidation(stepTwoStatesValidatioTwo)) return false;
    if (!isSelectedInternalDrainage)
      if (!handleValidation(stepTwoStatesValidatioThree)) return false;
    if (!isSelectedSurfaceDrainage)
      if (!handleValidation(stepTwoStatesValidatioFour)) return false;
    if (!handleValidation(stepTwoStatesValidationFive)) return false;
    setError(false);
    return true;
  }

  const handleStepTwo = async () => {
    if (validateStep2()) {
      const topicsSpecificData = ['Cracks', 'Repressions', 'Water Emergency'];
      const topicsGenericData = [
        'Presença de árvores ou arbustos',
        'Vegetação de cor mais destacadas',
        'Formigueiros / Cupins / Roedores',
        'Animais (verificar pegadas / fezes)',
        'Presença de tocas ou buracos',
        'Assoreamento da saída do dreno/colóide',
        'Carreamento visível de sólidos',
        'Alteração significativa da vazão',
        'Saturação ao redor do dreno',
        'Medidor de vazão operante',
        'Estado de limpeza',
        'Condições estruturais'
      ];
      const topicsGenericDataTwo = ['SurfaceErosions', 'Coating'];
      const genericData = stepTwoStates.filter((x: any) =>
        topicsGenericData.includes(x.topic)
      );
      const genericDataWithoutTopic = stepTwoStates.filter((x: any) =>
        topicsGenericDataTwo.includes(x.topic)
      );
      const data = genericData.map((x: any) => {
        const { fileSent, file, ...rest } = x;
        if (x.title === 'SurfaceDrainage') {
          if (isSelectedSurfaceDrainage) {
            rest.answer = AnswerEnum.NA;
            rest.observation = '';
          }
        }

        if (x.title === 'InternalDrainage') {
          if (isSelectedInternalDrainage) {
            rest.answer = AnswerEnum.NA;
            rest.observation = '';
          }
        }

        return rest;
      });
      const genericDataWithoutTopics = genericDataWithoutTopic.map((x: any) => {
        const { fileSent, file, topic, ...rest } = x;
        return rest;
      });

      const dataSend = [...data, ...genericDataWithoutTopics];
      if (dataSend.every((x) => x.id)) {
        const editResponse = await client.mutate({
          mutation: gql`
            mutation UpdateMssGenericData(
              $inspectionId: String!
              $newData: [UpdateMssGenericDataInput!]!
            ) {
              updateMssGenericData(
                inspectionId: $inspectionId
                newData: $newData
              )
            }
          `,
          variables: {
            inspectionId: inspectionId,
            newData: dataSend
          }
        });
        const newImagesGenerecData = genericData
          .concat(genericDataWithoutTopic)
          .map((state: any) => {
            return {
              images: state.file?.filter((file: any) => !file.id),
              id: state.id
            };
          });
        if (
          newImagesGenerecData.some(
            (images) => images.images && images.images.length > 0
          )
        ) {
          const formData = new FormData();
          newImagesGenerecData.forEach((files) => {
            if (files.images?.length > 0) {
              files.images.forEach((fileItem: any) => {
                formData.append(`mss=genericData=${files.id}`, fileItem);
              });
            }
          });
          await postSituationAccess(formData);
        }

        if (editResponse.data) {
          if (
            newImagesGenerecData.some(
              (images) => images.images && images.images.length > 0
            )
          ) {
            toastfySuccess(t('editedSuccessfully'));
            handleGetInspection();
            return true;
          } else {
            toastfySuccess(
              editResponse.data.updateMssGenericData || t('editedSuccessfully')
            );
            return true;
          }
        }

        return true;
      } else {
        const specificData = stepTwoStates.filter((x: any) =>
          topicsSpecificData.includes(x.topic)
        );
        const crack = specificData.filter((x: any) => x.topic === 'Cracks');
        const repression = specificData.filter(
          (x: any) => x.topic === 'Repressions'
        );
        const water = specificData.filter(
          (x: any) => x.topic === 'Water Emergency'
        );
        const crackData = crack.flatMap((x: any) => {
          if (x.answer === AnswerEnum.Yes) {
            return x.fields.map((y: any) => {
              const { fileSent, file, ...rest } = y;
              return rest;
            });
          } else {
            return [];
          }
        });
        const repressionData = repression.flatMap((x: any) => {
          if (x.answer === AnswerEnum.Yes) {
            const field = x.fields.map((y: any) => {
              const { fileSent, file, ...rest } = y;
              return rest;
            });
            return field;
          } else {
            return [];
          }
        });
        const waterData = water.flatMap((x: any) => {
          if (x.answer === AnswerEnum.Yes) {
            const field = x.fields.map((y: any) => {
              const { fileSent, file, ...rest } = y;
              return rest;
            });
            return field;
          } else {
            return [];
          }
        });
        const createResponse = await client.mutate({
          mutation: gql`
            mutation SaveMassiveStructureShoulder(
              $inspectionId: String!
              $data: SaveMassiveStructureShoulderDataInput!
            ) {
              saveMassiveStructureShoulder(
                inspectionId: $inspectionId
                data: $data
              ) {
                genericData {
                  id
                  title
                  answer
                  observation
                  topic
                }
                identifiedCracks {
                  id
                  name
                  length
                  opening
                  longitudinalTransverse
                  observation
                }
                identifiedDeformations {
                  identifiedAnomaly
                  id
                  local
                  observation
                }
                identifiedWaterLeaks {
                  id
                  name
                  local
                  visibleSolidTransport
                  observation
                }
              }
            }
          `,
          variables: {
            inspectionId: inspectionId,
            data: {
              genericData: [...data, ...genericDataWithoutTopics],
              identifiedCracks: crackData,
              identifiedDeformations: repressionData,
              identifiedWaterLeaks: waterData
            }
          }
        });
        if (createResponse.data) {
          const formData = new FormData();
          let count = 0;
          createResponse?.data?.saveMassiveStructureShoulder?.genericData?.forEach(
            (x: any) => {
              stepTwoStates.forEach((y: any) => {
                if (
                  y.topic === x.topic ||
                  y.topic === x.title ||
                  y.topic === x.title
                ) {
                  y.id = x.id;
                  if (y.fileSent && Array.isArray(y.file)) {
                    y.file.forEach((fileItem: any) => {
                      formData.append(`mss=genericData=${y.id}`, fileItem);
                      count++;
                    });
                  }
                }
              });
            }
          );
          createResponse?.data?.saveMassiveStructureShoulder?.identifiedCracks.forEach(
            (x: any) => {
              stepTwoStates.forEach((y: any) => {
                if (y.topic === 'Cracks') {
                  y.fields.forEach((files: any) => {
                    files.id = x.id;
                    files.file.forEach((fileItem: any) => {
                      formData.append(
                        `mss=identifiedCracks=${files.id}`,
                        fileItem
                      );
                      count++;
                    });
                  });
                }
              });
            }
          );
          createResponse?.data?.saveMassiveStructureShoulder?.identifiedDeformations.forEach(
            (x: any) => {
              stepTwoStates.forEach((y: any) => {
                if (y.topic === 'Repressions') {
                  y.fields.forEach((files: any) => {
                    files.id = x.id;
                    files.file.forEach((fileItem: any) => {
                      formData.append(
                        `mss=identifiedDeformations=${files.id}`,
                        fileItem
                      );
                      count++;
                    });
                  });
                }
              });
            }
          );
          createResponse?.data?.saveMassiveStructureShoulder?.identifiedWaterLeaks.forEach(
            (x: any) => {
              stepTwoStates.forEach((y: any) => {
                if (y.topic === 'Water Emergency') {
                  y.fields.forEach((files: any) => {
                    files.id = x.id;
                    files.file.forEach((fileItem: any) => {
                      formData.append(
                        `mss=identifiedWaterLeaks=${files.id}`,
                        fileItem
                      );
                      count++;
                    });
                  });
                }
              });
            }
          );
          if (count > 0) await postSituationAccess(formData);
          toastfySuccess(t('registeredSuccessfully'));
          handleGetInspection();
          return true;
        }
      }

      handleGetInspection();
    } else {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return false;
    }

    return false;
  };

  const handleStepSeven = async (
    stepStates: any[],
    generalComments: string
  ) => {
    if (stepStates.every((x) => x.id)) {
      const editResponse = await updateConservationState({
        variables: {
          inspectionId: inspectionId,
          newData: stepStates
        }
      });

      if (editResponse.data) {
        toastfySuccess(
          editResponse.data.updateConservationState || t('editedSuccessfully')
        );
      } else if (editResponse.errors) {
        toastfyError(
          editResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }

      if (generalComments) {
        if (!inspection?.findDataByInspection.generalComments) {
          const responseGeneralComments = await saveGeneralComments({
            variables: {
              inspectionId: inspectionId,
              generalComments: generalComments
            }
          });
          if (responseGeneralComments.data) {
            toastfySuccess(
              responseGeneralComments.data.generalComments ||
                t('registeredSuccessfully')
            );
          } else if (responseGeneralComments.errors) {
            toastfyError(
              responseGeneralComments.errors[0].message ||
                t(ToastifyModel().toastifyMessage.error)
            );
          }
        } else {
          const responseUpdateGeneralComments = await updateGeneralComments({
            variables: {
              inspectionId: inspectionId,
              newGeneralComments: generalComments
            },
            refetchQueries: [FindAllInspectionsByStructureDocument]
          });
          if (responseUpdateGeneralComments.data) {
            toastfySuccess(
              responseUpdateGeneralComments.data.updateGeneralComments ||
                t('registeredSuccessfully')
            );
          } else if (responseUpdateGeneralComments.errors) {
            toastfyError(
              responseUpdateGeneralComments.errors[0].message ||
                t(ToastifyModel().toastifyMessage.error)
            );
          }
        }
      }

      const responseGeneratePDF = await generateInspectionPdf({
        variables: {
          inspectionId: inspectionId
        }
      });
      if (responseGeneratePDF.data) {
        toastfySuccess(
          responseGeneratePDF.data.generateInspectionPdf ||
            t('registeredSuccessfully')
        );
        return true;
      } else if (responseGeneratePDF.errors) {
        toastfyError(
          responseGeneratePDF.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
        return false;
      }

      return true;
    } else {
      const createResponse = await saveConservationState({
        variables: {
          inspectionId: inspectionId,
          conservationState: stepStates
        }
      });
      if (createResponse.data) {
        toastfySuccess(
          createResponse.data.saveConservationState ||
            t('registeredSuccessfully')
        );
      } else if (createResponse.errors) {
        toastfyError(
          createResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }

      const responseGeneralComments = await saveGeneralComments({
        variables: {
          inspectionId: inspectionId,
          generalComments: generalComments
        }
      });
      if (responseGeneralComments.data) {
        toastfySuccess(
          responseGeneralComments.data.generalComments ||
            t('registeredSuccessfully')
        );
      } else if (responseGeneralComments.errors) {
        toastfyError(
          responseGeneralComments.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }

      const responseGeneratePDF = await generateInspectionPdf({
        variables: {
          inspectionId: inspectionId
        }
      });
      if (responseGeneratePDF.data) {
        toastfySuccess(
          responseGeneratePDF.data.generateInspectionPdf ||
            t('registeredSuccessfully')
        );
        return true;
      } else if (responseGeneratePDF.errors) {
        toastfyError(
          responseGeneratePDF.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
        return false;
      }

      return true;
    }
  };

  return (
    <ModalStep
      title={
        editing ? `${t('Edit')} ${t('Inspection')}` : 'RegisterNewInspection'
      }
      onClose={() => {
        setShowModal(!showModal);
        setShowStepsInspectionsModal(false);
      }}
      editing={editing}
      steps={[
        {
          name: 'AccessStatus',
          component: (
            <FirstStepInspection
              setStepOneStates={setStepOneStates}
              stepOneStates={stepOneStates}
              error={error}
              inspectionId={inspectionId}
            />
          ),
          onNext: () => handleStep(stepOneStates)
        },
        {
          name: 'DamMassif',
          component: (
            <SecondStepInspection
              setStepTwoStates={setStepTwoStates}
              stepTwoStates={stepTwoStates}
              isSelectedInternalDrainage={isSelectedInternalDrainage}
              setIsSelectedInternalDrainage={setIsSelectedInternalDrainage}
              isSelectedSurfaceDrainage={isSelectedSurfaceDrainage}
              setIsSelectedSurfaceDrainage={setIsSelectedSurfaceDrainage}
              error={error}
              inspectionId={inspectionId}
              mssStructure={
                inspection?.findDataByInspection.massiveStructureShoulder
              }
              setUpdateStepTwo={setUpdateStepTwo}
            />
          ),
          onNext: () => handleStepTwo()
        },
        {
          name: 'Reservoir',
          component: (
            <ThirdStepInspection
              setStepThreeStates={setStepThreeStates}
              stepThreeStates={stepThreeStates}
              error={error}
              inspectionId={inspectionId}
            />
          ),
          onNext: () => handleStep(stepThreeStates)
        },
        {
          name: 'Extravasators',
          component: (
            <FourthStepInspection
              setStepFourStates={setStepFourStates}
              stepFourStates={stepFourStates}
              error={error}
              inspectionId={inspectionId}
            />
          ),
          onNext: () => handleStep(stepFourStates)
        },
        {
          name: 'Instrumentation',
          component: (
            <FifthStepInspection
              setStepFiveStates={setStepFiveStates}
              stepFiveStates={stepFiveStates}
              error={error}
              inspectionId={inspectionId}
            />
          ),
          onNext: () => handleStep(stepFiveStates)
        },
        {
          name: 'WastePipelinesWwaterCollection',
          component: (
            <SixthStepInspection
              setStepSixStates={setStepSixStates}
              stepSixStates={stepSixStates}
              error={error}
              inspectionId={inspectionId}
            />
          ),
          onNext: () => handleStep(stepSixStates)
        },
        {
          name: 'ConservationState',
          component: (
            <SeventhStepInspection
              setStepSevenStates={setStepSevenStates}
              stepSevenStates={stepSevenStates}
              setGeneralComments={setGeneralComments}
              generalComments={generalComments}
            />
          ),
          onNext: () => handleStepSeven(stepSevenStates, generalComments)
        }
      ]}
    />
  );
}
