import styled from 'styled-components';

export const DivContainerComment = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  padding: 25px 0;
  gap: 10px;
  box-sizing: border-box;

  p {
    margin: 0;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  p {
    margin: 0;
  }
`;

export const DivAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  width: 20%;
`;

export const DivContainerTiltle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 10px 0 0;
  box-sizing: border-box;
`;

export const DivComment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
