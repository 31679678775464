import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { MdDelete, MdOutlineDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { DefaultButton } from '../../Components/Buttons/DefaultButton';
import { DatePickerComponent } from '../../Components/DatePicker';
import { DefaultInput } from '../../Components/Inputs/InputsStyle';
import { DataBaseButtonArea } from '../../Components/Instruments/Readings/DataBaseStyles';
import { TableComponentInspectionStyle } from '../../Components/Tables/TablesStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../Components/Toastify';
import Button from '../../Components/V2/Atoms/Button/Button';
import { ProjectContext } from '../../Context/ContextAPI';
import ExportData from '../../Controllers/Export/ExportData';
import ReadingsController from '../../Controllers/Instruments/ReadingsController';
import ToastifyModel from '../../Models/ToastifyModel';
import { ActionsRow } from '../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../Styles/Styles';
import { useFindAllInspectionsByStructure } from '../../data/hooks/use-find-all-inspections.mutation';
import { API_URL, BUCKET_URL } from '../../utils/const';
import { AttributionInspection } from './Attribution/AttributionInspection';
import { InspectionDeleteModal } from './InspectionDeleteModal';
import {
  DivContaineDate,
  Filters,
  Inputs,
  InspectionsContainer
} from './InspectionsStyle';

interface InspectionType {
  conservationStateCalc: number;
  date: string;
  generalComments: string;
  id: string;
  participants: InspectionParticipantsType[];
  responsible: string;
  structureId: string;
  systemId: number;
  systemPdf: string;
  userPdf: string;
}

interface InspectionParticipantsType {
  company: string;
  id: string;
  name: string;
  position: string;
  signature: string;
  status: boolean;
}

export default function InspectionsScreen() {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const [editing, setEditing] = useState<boolean>(false);
  const [showInspectionsModal, setShowInspectionsModal] =
    useState<boolean>(false);

  const [filterName, setFilterName] = useState<string>('');
  const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [filterId, setFilterId] = useState<string>('');

  const [inspectionId, setInspectionId] = useState('');
  const [selectedInspection, setSelectedInspection] =
    useState<InspectionType | null>(null);

  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Inspection');

  const [deleting, setDeleting] = useState<{
    status: boolean;
    data: any;
  }>({
    status: false,
    data: null
  });

  const {
    data: listAssociated,
    loading,
    error
  } = useFindAllInspectionsByStructure(structureId || '');

  const filteredInspections = useMemo(() => {
    if (!listAssociated) return [];

    return listAssociated.findAllInspectionsByStructure.filter((inspection) => {
      const nameMatches = inspection.responsible
        .toLowerCase()
        .startsWith(filterName.toLowerCase());

      const dateMatches = filterDate
        ? new Date(inspection.date).getFullYear() ===
            filterDate.getFullYear() &&
          new Date(inspection.date).getMonth() === filterDate.getMonth() &&
          new Date(inspection.date).getDate() === filterDate.getDate()
        : true;

      const idMatches =
        filterId === '' ? true : inspection.systemId === parseInt(filterId);

      return nameMatches && dateMatches && idMatches;
    });
  }, [filterName, filterDate, filterId, listAssociated]);

  useEffect(() => {
    if (error) {
      toastfyError(translate(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loading) {
      toastifyLoading('Carregando inspeções...');
    } else if (listAssociated) {
      toastfyDimiss('toastLoading');
    }
  }, [error, listAssociated, loading, translate]);

  function handleId(id: number) {
    if (id <= 9) {
      return '00' + id;
    } else if (id > 9 && id < 99) {
      return '0' + id;
    }

    return id;
  }

  const handleEditData = async (inspection: any) => {
    setEditing(true);
    setShowInspectionsModal(true);
    setInspectionId(inspection.id);
    setSelectedInspection(inspection);
  };

  const handleDownloadFile = async (inspection: any) => {
    try {
      const url = `${BUCKET_URL}/${inspection.userPdf}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Inspection_${inspection.systemId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastfyError(translate('FailedtoDownloadFile File'));
    }
  };

  const handleDownloadFilePDFInspection = async (inspection: any) => {
    try {
      const response = await fetch(`${API_URL}/${inspection.systemPdf}`);
      if (!response.ok) {
        toastfyError(translate('FailedtoDownloadFile: ') + response.statusText);
        return;
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Inspection_${inspection.systemId}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastfyError(translate('FailedtoDownloadFile: '));
    }
  };

  return (
    <InspectionsContainer>
      <Filters>
        <h3>{translate('Filters')}</h3>
        <Inputs style={{ width: '75%' }}>
          <DefaultInput
            style={{ width: '193px' }}
            placeholder={translate('Id')}
            value={filterId}
            onChange={(event) => setFilterId(event.target.value)}
          />
          <DefaultInput
            style={{ width: '193px' }}
            placeholder={translate('name')}
            value={filterName}
            onChange={(event) => setFilterName(event.target.value)}
          />
          <DivContaineDate>
            {translate('Date')}:
            <DatePickerComponent
              startDate={filterDate}
              setStartDate={setFilterDate}
              showTimeSelect={false}
              dateText={translate('FilteredDate')}
            />
            <Button
              text={translate('clean')}
              variant="primary"
              size="small"
              onClick={() => setFilterDate(null)}
            />
          </DivContaineDate>
        </Inputs>
      </Filters>
      <div style={{ height: '500px' }}>
        <TableComponentInspectionStyle>
          <thead>
            <tr>
              <th>{translate('ID')}</th>
              <th>{translate('name')}</th>
              <th>{translate('Date')}</th>
              <th>{translate('ConservationState') + ' (∑EC)'}</th>
              <th>{translate('InspectionForm')}</th>
              <th>{translate('FieldSheet')}</th>
              {userRoleInModule !== 'Viewer' && <th>{translate('actions')}</th>}
            </tr>
          </thead>
          <tbody style={{ overflowY: 'scroll' }}>
            {filteredInspections?.map((inspection, index: number) => (
              <tr key={index}>
                <td>{handleId(inspection.systemId)}</td>
                <td>{inspection.responsible}</td>
                <td>{new Date(inspection.date).toLocaleDateString('pt-br')}</td>
                <td>
                  {inspection.conservationStateCalc
                    ? inspection.conservationStateCalc
                    : '-'}
                </td>
                <td>
                  {inspection.systemPdf ? (
                    <MdOutlineDownload
                      style={{
                        color: Color.Brown1,
                        cursor: 'pointer',
                        fontSize: '20px'
                      }}
                      onClick={() =>
                        handleDownloadFilePDFInspection(inspection)
                      }
                    />
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {inspection.userPdf ? (
                    <MdOutlineDownload
                      style={{
                        color: Color.Brown1,
                        cursor: 'pointer',
                        fontSize: '20px'
                      }}
                      onClick={() => handleDownloadFile(inspection)}
                    />
                  ) : (
                    '-'
                  )}
                </td>
                {userRoleInModule !== 'viewer' && (
                  <td>
                    <ActionsRow>
                      <BiSolidPencil
                        onClick={() => {
                          handleEditData(inspection);
                        }}
                      />
                      <MdDelete
                        onClick={() => {
                          setDeleting({ status: true, data: inspection });
                        }}
                      />
                    </ActionsRow>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TableComponentInspectionStyle>
      </div>
      <DataBaseButtonArea>
        <DefaultButton
          title="ExportTable"
          action={() => {
            ExportData.exportInspectionList(filteredInspections);
          }}
        />
        {userRoleInModule !== 'viewer' && (
          <DefaultButton
            title="RegisterNewInspection"
            action={() => {
              setEditing(false);
              setInspectionId('');
              setSelectedInspection(null);
              ReadingsController.ShowModal({
                showModal: showInspectionsModal,
                setShowModal: setShowInspectionsModal
              });
            }}
          />
        )}
      </DataBaseButtonArea>
      {showInspectionsModal && !deleting.status && (
        <AttributionInspection
          setShowModal={setShowInspectionsModal}
          editing={editing}
          inspectionId={inspectionId}
          setInspectionId={setInspectionId}
          selectedInspection={selectedInspection}
        />
      )}
      {deleting.status && (
        <InspectionDeleteModal
          id={deleting.data.id}
          name={handleId(deleting.data.systemId)}
          setShowModal={setShowInspectionsModal}
          setDeleting={setDeleting}
        />
      )}
    </InspectionsContainer>
  );
}
