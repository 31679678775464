import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputFile,
  InspectionInputsDiv
} from '../../../../Components/Forms/FormInspectionsStyle';
import { DefaultInput } from '../../../../Components/Inputs/InputsStyle';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';
import {
  RadioCheboxArea,
  RadioCheck,
  RadioCheckbox
} from '../../../../Styles/Styles';
import { AnswerEnum } from '../../../../data/graphql/base-schema';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { toastfyError } from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { API_URL, BUCKET_URL } from '../../../../utils/const';
import FileList from '../../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';

export function Coating({
  setStepStates,
  stepStates,
  topic,
  error,
  inspectionId
}: {
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: any;
  topic: string;
  error: boolean;
  inspectionId: string;
}) {
  const [checkBox, setCheckBox] = useState([
    { checked: false },
    { checked: false },
    { checked: false }
  ]);
  const [observationArea, setObservationArea] = useState('');
  const [img, setImg] = useState<FileType[]>([]);
  const { t }: any = useTranslation();

  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const httpLink = new HttpLink({
    uri: `${API_URL}/graphql`
  });
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: userData.token
      }
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  useEffect(() => {
    if (stepStates.length > 0) {
      stepStates.map((item: any) => {
        if (item.topic === topic) {
          setCheckBox([
            { checked: item.answer === AnswerEnum.Good },
            { checked: item.answer === AnswerEnum.Regular },
            { checked: item.answer === AnswerEnum.Deficient }
          ]);
          setObservationArea(item.observation);
          if (item.file?.length > 0) {
            const fileURLs: any[] = [];
            item.file.map((image: any) => {
              if (image.image) {
                fileURLs.push({
                  id: image.id,
                  name: image.image,
                  url: `${BUCKET_URL}/${image.image}`
                });
              } else {
                fileURLs.push(image);
              }
            });
            setImg(fileURLs);
          }
        }
      });
    }
  }, [stepStates]);

  const handleChange = (value: AnswerEnum, topic: string) => {
    const updatedStepStates = stepStates.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, answer: value };
      }

      return item;
    });
    setStepStates(updatedStepStates);
    setCheckBox([
      { checked: value === AnswerEnum.Good },
      { checked: value === AnswerEnum.Regular },
      { checked: value === AnswerEnum.Deficient }
    ]);
  };

  const handleChangeObservation = (value: string) => {
    const updatedState = stepStates.map((item: any) => {
      if (item.topic === topic) {
        return { ...item, observation: value };
      }

      return item;
    });
    setStepStates(updatedState);
    setObservationArea(value);
  };

  const hanldeFileChange = (files: any) => {
    const updatedStepStates = stepStates.map((item: any) => {
      const existingFiles = Array.isArray(item.file) ? item.file : [];
      if (item.topic === topic) {
        return {
          ...item,
          fileSent: true,
          file: [...existingFiles, ...files]
        };
      }

      return item;
    });
    setStepStates(updatedStepStates);
    const updatedImg = img.concat(
      Array.from(files).map((file: any) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file)
        };
      })
    );
    setImg(updatedImg);
  };

  const handleDeleteFile = async (imgSelect: FileType) => {
    if (imgSelect.id) {
      const deleteResponse = await client.mutate({
        mutation: gql`
          mutation DeleteInspectionDataImage(
            $image: DeleteInspectionDataImageInput!
            $inspectionId: String!
          ) {
            deleteInspectionDataImage(
              image: $image
              inspectionId: $inspectionId
            )
          }
        `,
        variables: {
          inspectionId: inspectionId,
          image: {
            id: imgSelect.id,
            dataType: 'mssGenericData'
          }
        }
      });
      if (deleteResponse.data) {
        const filteredImages = img.filter(
          (image: FileType) => image.id !== imgSelect.id
        );
        setImg(filteredImages);
        const updatedStepStates = stepStates.map((item: any) => {
          if (item.topic === topic) {
            return { ...item, file: filteredImages };
          }

          return item;
        });
        setStepStates(updatedStepStates);
        return true;
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
        return false;
      }
    } else {
      const updatedImg = img.filter((item: FileType) => item !== imgSelect);
      setImg(updatedImg);
      const updatedStepStates = stepStates.map((item: any) => {
        const existingFiles = item.file?.filter(
          (file: any) => file !== imgSelect
        );
        if (item.topic === topic) {
          return { ...item, file: existingFiles };
        }

        return item;
      });
      setStepStates(updatedStepStates);
      return true;
    }
  };

  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t(topic)}</span>
      </IdentifiesHeader>
      <TableComponentStyle>
        <thead>
          <tr>
            <th>{t('Value')}</th>
            <th>{t('Observation')}</th>
            <th>{t('Image')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <InspectionInputsDiv
                style={{
                  outline:
                    error && !checkBox?.some((x: any) => x.checked)
                      ? '1px solid red'
                      : 'none'
                }}
              >
                <RadioCheboxArea style={{ left: 'unset' }}>
                  <RadioCheckbox>
                    <input
                      type="radio"
                      name={`good${topic}`}
                      id={`good${topic}`}
                      data-input="answer"
                      data-answer={AnswerEnum.Good}
                      checked={checkBox[0].checked}
                      onChange={() => handleChange(AnswerEnum.Good, topic)}
                    />
                    <div className="transition"></div>
                  </RadioCheckbox>
                  <RadioCheck htmlFor={`good${topic}`}>{t('Good')}</RadioCheck>
                </RadioCheboxArea>
                <RadioCheboxArea style={{ left: 'unset' }}>
                  <RadioCheckbox>
                    <input
                      type="radio"
                      name={`regular${topic}`}
                      id={`regular${topic}`}
                      data-input="answer"
                      data-answer={AnswerEnum.Regular}
                      checked={checkBox[1].checked}
                      onChange={() => {
                        handleChange(AnswerEnum.Regular, topic);
                      }}
                    />
                    <div className="transition"></div>
                  </RadioCheckbox>
                  <RadioCheck htmlFor={`regular${topic}`}>
                    {t('Regular')}
                  </RadioCheck>
                </RadioCheboxArea>
                <RadioCheboxArea style={{ left: 'unset' }}>
                  <RadioCheckbox>
                    <input
                      type="radio"
                      name={`deficient${topic}`}
                      id={`deficient${topic}`}
                      data-input="answer"
                      data-answer={AnswerEnum.Deficient}
                      checked={checkBox[2].checked}
                      onChange={() => {
                        handleChange(AnswerEnum.Deficient, topic);
                      }}
                    />
                    <div className="transition"></div>
                  </RadioCheckbox>
                  <RadioCheck htmlFor={`deficient${topic}`}>
                    {t('Deficient')}
                  </RadioCheck>
                </RadioCheboxArea>
              </InspectionInputsDiv>
            </td>
            <td>
              <DefaultInput
                style={{ width: '293px' }}
                placeholder={`${t('Observation')} `}
                name="observation"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleChangeObservation(e.target.value);
                }}
                value={observationArea}
                error={error && !observationArea && !checkBox[0].checked}
              />
            </td>
            <td>
              <InputFile>
                <input
                  type="file"
                  name={`Image ${topic}`}
                  accept=".png, .jpg, .jpeg"
                  id={`Image ${topic}`}
                  multiple
                  onChange={(e) => {
                    hanldeFileChange(e.target.files);
                  }}
                  style={{
                    outline:
                      error && img.length <= 0 && !checkBox[0].checked
                        ? '1px solid red'
                        : 'none'
                  }}
                />
              </InputFile>
              {img.length > 0 && (
                <FileList
                  files={img?.map((item) => item)}
                  onDelete={(img: FileType) => {
                    handleDeleteFile(img);
                  }}
                />
              )}
            </td>
          </tr>
        </tbody>
      </TableComponentStyle>
    </IdentifiesContainer>
  );
}
